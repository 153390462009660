import React from "react";
import { Container } from "react-bootstrap";
import { AmendmentItem } from "../index";

function Amendment(props) {
  let details = props && props.details;
  return (
    <Container fluid>
      {details &&
        details.map(function (item, i) {
          return <AmendmentItem key={i} details={item} />;
        })}
    </Container>
  );
}

export default Amendment;
