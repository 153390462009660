import React, { useState, useContext, useEffect } from "react";
import { Container, Nav, Tab, Col, Row, Image } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import { GlobalContext } from "../../context/GlobalState";
import { iconChecked, iconCheck } from "../../lib/images";
import { useTranslation } from "react-i18next";

function AmendmentItem(props) {
  // let isRecommendation = config.featureRecommendation;
  const [key, setKey] = useState("title");

  let details = props.details;
  let titleDisplay = details.amendment_id;
  // let recommendedText = details.recommendation === 1 ? "YES" : "NO";
  let amendmentTitle, amendmentContent;
  let { t } = useTranslation();
  const { state, addSelection, removeSelection } = useContext(GlobalContext);
  let candidateSelection = state.candidateSelection;
  const [isSelected, setSelected] = useState("");
  useEffect(() => {
    let findSelection = candidateSelection.find(
      (selection) => selection.race === titleDisplay,
    );
    if (findSelection) {
      if (findSelection.selection === "Yes") {
        setSelected("Yes");
      } else {
        setSelected("No");
      }
    } else {
      setSelected("");
    }
  }, [state, candidateSelection, details, titleDisplay]);
  switch (key) {
    case "what":
      amendmentContent = details.description[0].children[0].text;
      // amendmentTitle = details.amendment_id;
      break;
    case "title":
      amendmentContent = details.description[0].children[0].text;
      amendmentTitle = details.amendment_title;
      break;
    default:
      amendmentContent = details.description[0].children[0].text;
      amendmentTitle = details.amendment_title;
      break;
  }

  const toggleSelect = (selected) => {
    setSelected(selected);
    let payload = {
      race: titleDisplay,
      selection: selected === "" ? isSelected : selected,
    };
    if (selected === "") {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "amendment_unselect",
        amendment: titleDisplay,
        vote: isSelected,
      });
      removeSelection(payload);
    } else {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "amendment_select",
        amendment: titleDisplay,
        vote: selected,
      });
      addSelection(payload);
    }
  };
  let disableSelection = state.step === 1;

  return (
    <div>
      <Container className="ballot-container" fluid>
        <Container>
          <Row className="title-background">
            <Container>
              <div className="title">{details.amendment_id}</div>
              {/* <Tab.Container
                activeKey={key}
                onSelect={(k) => setKey(k)}
                id="uncontrolled-tab-example"
                style={{ display: "inline-flex" }}
                transition="fade"
              >
                <Row className="tab-manager">
                  <Nav.Item className="main-tab">
                    <Nav.Link
                      eventKey="title"
                      title="Measure"
                      className={key === "title" ? "active-tab" : ""}
                    >
                      {t("ballot_measure_title")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="main-tab">
                    <Nav.Link
                      eventKey="what"
                      title="What"
                      className={key === "what" ? "active-tab" : ""}
                    >
                      {t("what_it_does")}
                    </Nav.Link>
                  </Nav.Item>
                </Row>
              </Tab.Container> */}
            </Container>
          </Row>
          <Row>
            <Container className="candidate">
              <Container
                className={`candidate-container candidate-mobile amendment-container`}
                fluid
              >
                <Container>
                  <Row>
                    <Col
                      xs={{ span: 12, order: 2 }}
                      md={{ span: 12, order: 1 }}
                      lg="8"
                      style={{ textAlign: "left" }}
                    >
                      <h3 className="amendment-tittle">{amendmentTitle}</h3>
                      <p>{ReactHtmlParser(amendmentContent)}</p>
                    </Col>
                    <Col
                      xs={{ span: 12, order: 1 }}
                      md={{ span: 12, order: 2 }}
                      lg="4"
                      className="measures-select"
                      style={{ display: disableSelection ? "none" : "flex" }}
                    >
                      <div className="candidate-select clickable">
                        <div
                          className="user-checked"
                          onClick={() =>
                            toggleSelect(isSelected === "Yes" ? "" : "Yes")
                          }
                        >
                          {isSelected === "Yes" ? (
                            <Image
                              src={iconChecked}
                              alt=""
                              className="icon-user-checked"
                            />
                          ) : null}
                          <div className="user-checked-checkbox">
                            <Image
                              src={iconCheck}
                              alt=""
                              className="icon-user-unchecked"
                            />
                          </div>
                          <div
                            className={
                              isSelected === "Yes"
                                ? "user-checked-title selected"
                                : isSelected === "No"
                                ? "user-checked-title unselect"
                                : "user-checked-title"
                            }
                          >
                            {isSelected === "Yes"
                              ? t("voting_yes")
                              : t("vote_yes")}
                          </div>
                        </div>
                      </div>
                      <div className="candidate-select clickable">
                        <div
                          className="user-checked"
                          onClick={() =>
                            toggleSelect(isSelected === "No" ? "" : "No")
                          }
                        >
                          {isSelected === "No" ? (
                            <Image
                              src={iconChecked}
                              alt=""
                              className="icon-user-checked"
                            />
                          ) : null}
                          <div className="user-checked-checkbox">
                            <Image
                              src={iconCheck}
                              alt=""
                              className="icon-user-unchecked"
                            />
                          </div>
                          <div
                            className={
                              isSelected === "No"
                                ? "user-checked-title selected"
                                : isSelected === "Yes"
                                ? "user-checked-title unselect"
                                : "user-checked-title"
                            }
                          >
                            {isSelected === "No"
                              ? t("voting_no")
                              : t("vote_no")}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {/* <Row>
                  <Col
                    xs={{ span: 12, offset: 0 }}
                    style={{ textAlign: "left" }}
                  >
                    <p>{ReactHtmlParser(amendmentContent)}</p>
                  </Col>
                </Row> */}
                </Container>
              </Container>
            </Container>
          </Row>
        </Container>
      </Container>
    </div>
  );
}

export default AmendmentItem;
