import React from "react";
import { withTranslation } from "react-i18next";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import "./locationSearchInput.styles.scss";

class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: "" };
  }

  handleChange = (address) => {
    this.setState({ address });
    this.props.updateAddress(address);
  };

  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        this.setState({ address });
        this.props.updateAddress(address);
      })
      .catch((error) => console.error("Error", error));
  };

  render() {
    const { t } = this.props;
    const searchOptions = {
      locationBias: {
        north: 35.0,
        south: 30.4,
        west: -85.6,
        east: -80.8,
      },
      strictBounds: false,
      types: ['address'],
    };
    return (
			<PlacesAutocomplete
				value={this.state.address}
				onChange={this.handleChange}
				onSelect={this.handleSelect}
				searchOptions={searchOptions}>
				{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
					const gaUsaSuggestions = suggestions.filter((suggestion) =>
						suggestion.description.includes("GA, USA")
					);

					return (
						<div>
							<input
								{...getInputProps({
									placeholder: t("enter_your_address_search"),
									className: "address-input",
								})}
							/>
							<div
								className='autocomplete-dropdown-container'
								style={{
									display: gaUsaSuggestions.length > 0 ? "block" : "none",
								}}>
								{gaUsaSuggestions.map((suggestion, i) => {
									const className = suggestion.active
										? "suggestion-item--active clickable"
										: "suggestion-item clickable";
									const style = suggestion.active
										? { backgroundColor: "#EC704C", cursor: "inherit" }
										: { backgroundColor: "#F5F5F5", cursor: "inherit" };

									return (
										<div
											{...getSuggestionItemProps(suggestion, {
												className,
												style,
											})}
											key={i}>
											<span>{suggestion.description.replace(", USA", "")}</span>
										</div>
									);
								})}
							</div>
						</div>
					);
				}}
			</PlacesAutocomplete>
		);
  }
}

export default withTranslation()(LocationSearchInput);
