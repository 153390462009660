// import meta images
export const metaDefaultImage = "/images/007-GAVG 2022-SocialShareDefault-1200x630-v1.png";
export const metaDefaultQuiz = "/images/007-GAVG 2022-SocialShareQuiz-1200x630-v1.png";
export const metaSoloHD34 = "/images/007-GeorgiaVoterGuide-SoloRaceWebsiteImage-HD34-1200x628-v1.jpg";
export const metaSoloHD35 = "/images/007-GeorgiaVoterGuide-SoloRaceWebsiteImage-HD35-1200x628-v1.jpg";
export const metaSoloHD43 = "/images/007-GeorgiaVoterGuide-SoloRaceWebsiteImage-HD43-1200x628-v1.jpg";
export const metaSoloHD44 = "/images/007-GeorgiaVoterGuide-SoloRaceWebsiteImage-HD44-1200x628-v1.jpg";
export const metaSoloHD45 = "/images/007-GeorgiaVoterGuide-SoloRaceWebsiteImage-HD45-1200x628-v1.jpg";
export const metaSoloHD49 = "/images/007-GeorgiaVoterGuide-SoloRaceWebsiteImage-HD49-1200x628-v1.jpg";
export const metaSoloHD52 = "/images/007-GeorgiaVoterGuide-SoloRaceWebsiteImage-HD52-1200x628-v1.jpg";
export const metaSoloHD73 = "/images/007-GeorgiaVoterGuide-SoloRaceWebsiteImage-HD73-1200x628-v1.jpg";
export const metaSoloHD109 = "/images/007-GeorgiaVoterGuide-SoloRaceWebsiteImage-HD109-1200x628-v1.jpg";
export const metaSoloHD110 = "/images/007-GeorgiaVoterGuide-SoloRaceWebsiteImage-HD110-1200x628-v1.jpg";
export const metaSoloHD117 = "/images/007-GeorgiaVoterGuide-SoloRaceWebsiteImage-HD117-1200x628-v1.jpg";
export const metaSoloHD119 = "/images/007-GeorgiaVoterGuide-SoloRaceWebsiteImage-HD119-1200x628-v1.jpg";
export const metaSoloHD145 = "/images/007-GeorgiaVoterGuide-SoloRaceWebsiteImage-HD145-1200x628-v1.jpg";
export const metaSoloHD147 = "/images/007-GeorgiaVoterGuide-SoloRaceWebsiteImage-HD147-1200x628-v1.jpg";
export const metaSoloHD151 = "/images/007-GeorgiaVoterGuide-SoloRaceWebsiteImage-HD151-1200x628-v1.jpg";
export const metaSoloHD164 = "/images/007-GeorgiaVoterGuide-SoloRaceWebsiteImage-HD164-1200x628-v2.jpg";

//import sponsors
export const sponsor1 = {
  src: "/images/orglogos/2024/GA-PNG-COLOR- STACKED.png",
  altTxt: "9 to 5 - Georgia Voter Guide Sponsor",
  link: "",
};
export const sponsor2 = {
  src: "/images/orglogos/2024/Black Voters Matter.png",
  altTxt: "Black Voters Matter - Georgia Voter Guide Sponsor",
  link: "",
};
export const sponsor3 = {
  src: "/images/orglogos/2024/CASANew - Luis Zaldivar.png",
  altTxt: "CASA - Georgia Voter Guide Sponsor",
  link: "",
};
export const sponsor4 = {
  src: "/images/orglogos/2024/CollectiveRenaissance - Camille Thompson.png",
  altTxt: "Collective Renaissance Georgia - Georgia Voter Guide Sponsor",
  link: "",
};
export const sponsor5 = {
  src: "/images/orglogos/2024/EFG Logo verticle - Jeff Graham.png",
  altTxt: "Equality Foundation of Georgia - Georgia Voter Guide Sponsor",
  link: "",
};
export const sponsor6 = {
  src: "/images/orglogos/2024/GA VALUES ACTION LOGO - Joey McKinnon copy.png",
  altTxt: "Georgia Values Action - Georgia Voter Guide Sponsor",
  link: "",
};
export const sponsor7 = {
  src: "/images/orglogos/2024/Georgia Youth Justice Coalition PFP Circle - Melody Oliphant.png",
  altTxt: "Georgia Youth Justice Coalition - Georgia Voter Guide Sponsor",
  link: "",
};
export const sponsor8 = {
  src: "/images/orglogos/2024/GLAHR LOGO - SVG - Sebastian Saavedra.svg",
  altTxt: "GLAHR Action Network / Mijente - Georgia Voter Guide Sponsor",
  link: "",
};
export const sponsor9 = {
  src: "/images/orglogos/2024/GoodPeopleCollective.png",
  altTxt: "Good People Collective - Georgia Voter Guide Sponsor",
  link: "",
};
export const sponsor10 = {
  src: "/images/orglogos/2024/IGC logo - Abby Tennenbaum.png",
  altTxt: "Indivisible Georgia Coalition - Georgia Voter Guide Sponsor",
  link: "",
};
export const sponsor11 = {
  src: "/images/orglogos/2024/MFA_logo - Jocelyne Soto.svg",
  altTxt: "MFA - Georgia Voter Guide Sponsor",
  link: "",
};
export const sponsor12 = {
  src: "/images/orglogos/2024/NGP_Logo_LG - Stephanie Ali.png",
  altTxt: "New Georgia Project - Georgia Voter Guide Sponsor",
  link: "",
};
export const sponsor13 = {
  src: "/images/orglogos/2024/PPSE Logo (Black) - Kaylah Oates-Marable.png",
  altTxt: "Planned Parenthood Southeast Inc - Georgia Voter Guide Sponsor",
  link: "",
};
export const sponsor14 = {
  src: "/images/orglogos/2024/Poder Latinx Logo - Leslie Palomino.png",
  altTxt: "PODER LATINX - Georgia Voter Guide Sponsor",
  link: "",
};
export const sponsor15 = {
  src: "/images/orglogos/2024/SPLC_Monogram-black.png",
  altTxt: "SPLC - Georgia Voter Guide Sponsor",
  link: "",
};
export const sponsor16 = {
  src: "/images/orglogos/2024/UnitedforRespect.png",
  altTxt: "United for Respect Education Fund - Georgia Voter Guide Sponsor",
  link: "",
};

//import illustrations

export const info1 = { src: "/images/WinningMark_Illustration_1.png", altTxt: "Vote by mail - Georgia Voter Information for the 2022 Election" };
export const info2 = { src: "/images/WinningMark_Illustration_2.png", altTxt: "Forms of ID accepted for in person voting - Georgia Voter Information for the 2022 Election" };
export const info3 = "/images/WinningMark_Illustration_3.png";
export const info4 = { src: "/images/WinningMark_Illustration_4.png", altTxt: "Get Georgia Voter Information for the 2022 Election", altTxt_2: "Vote in person on Election Day - Georgia Voter Information for the 2022 Election" };
export const info5 = { src: "/images/023-GeorgiaVoterGuide-VoteEarlyInPersonImageUpdate.jpg", altTxt: "Georgia Voter Information for the 2022 Election" };

export const smallEnv = { src: "/images/small-env.png", altTxt: "Small envelope version of absentee ballot - Georgia Voter Information for the 2022 Election" };
export const bigEnvFront = { src: "/images/big-env-front.png", altTxt: "Front of big envelope version of absentee ballot - Georgia Voter Information for the 2022 Election" };
export const bigEnvBack = { src: "/images/big-env-back.jpg", altTxt: "Back of big envelope version of absentee ballot - Georgia Voter Information for the 2022 Election" };

//import run-off assets

export const check = { src: "/images/check.png", altTxt: "Yes" };
export const x = { src: "/images/x.png", altTxt: "No" };
export const voteBg = "/images/vote.jpg";
export const earlyCalendar = { src: "/images/early-calendar.png", altTxt: "Important calendar dates for early in person voting - Georgia Voter Information" };
export const electionCalendar = { src: "/images/election-calendar.png", altTxt: "Calendar highlighting the date for the Georgia 2022 Election" };
export const mailCalendar = { src: "/images/mail-calendar.png", altTxt: "Important calendar dates for voting by mail - Georgia Voter Information for the 2022 Election" };
export const voted = "/images/Voted.png";
export const altColorV2 = "/images/Alt-Color_V2.png";
export const starIcon = "/images/star-icon.png";
export const starIconOrange = "/images/star-icon-orange.png";
export const closeIcon = "/images/img-close.png";
export const badgeFactDark = "/images/badgeFactDark.png";
export const badgeFactLight = "/images/badgeFactLight.png";
export const closeIconGreen = "/images/img-close_green.png";
export const closeIconLight = "/images/img-close_light.png";
export const iconHandRight = "/images/iconHandRight.png";
export const iconHandRightWhite = "/images/iconHandRightWhite.png";
export const iconDesktop = "/images/iconDesktop.png";
export const iconLocation = "/images/iconLocation.png";
export const iconEmail = "/images/iconEmail.png";
export const iconPlus = "/images/iconPlus.png";
export const iconMinus = "/images/iconMinus.png";
export const iconWarning = "/images/iconWarning.png";
export const iconHandOrange = "/images/iconHandOrange.png";
export const iconStartOver = "/images/iconStartOver.png";
export const iconQuizLP01 = "/images/QuizLP01.png";
export const iconQuizLP02 = "/images/QuizLP02.png";
export const iconQuizLP03 = "/images/QuizLP03.png";
export const iconQuizLP04 = "/images/QuizLP04.png";
export const iconQuizLP05 = "/images/QuizLP05.png";
export const checkRegister = "/images/check-register.png";
export const infoRegister = "/images/info-register.png";
export const logoPlannedParenthood = "/images/PlannedParenthood.png";
export const logoAAAFLogo = "/images/AAAFLogo.png";
export const logoBlackMaleVoterProject = "/images/BlackMaleVoterProject.png";
export const logoNARALGALogo = "/images/NARALGALogo.png";
export const logoSierraClubGAChapterLogo_v1 = "/images/SierraClubGAChapterLogo_v1.png";
export const logoVoteProChoice = "/images/VoteProChoice.png";
export const logoGCV = "/images/GCV.png";
export const logoGLAHRActionNetworkMorado = "/images/GLAHRActionNetworkMorado.png";
export const logoCareinActionLogo = "/images/CareinActionLogo.png";
export const logoMijenteLogo = "/images/MijenteLogo.png";
export const logoGeorgiaEquality = "/images/GeorgiaEquality.png";
export const logoNGPAFLogo = "/images/NGPAFLogo.png";
export const logoNAACPGALogo = "/images/NAACPGALogo.png";
export const logoEnvironmentGeorgiaLogo = "/images/EnvironmentGeorgiaLogo.png";
export const logoBVMFundLogo = "/images/BVMFundLogo.png";
export const iconDropdown = "/images/iconDropdown.png";
export const iconDropdownBlack = "/images/iconDropdownBlack.png";
export const iconDropdownWhite = "/images/iconDropdownWhite.png";
export const imageBallot = "/images/imageBallot.png";
export const imageBallotMobile = "/images/imageBallot_mob.png";
export const iconDone = "/images/iconDone.png";
export const logoHeader = "/images/logo-header.svg";
export const iconCheck = "/images/iconCheck.png";
export const iconChecked = "/images/iconChecked.png";
export const iconCheckMark = "/images/iconCheckMark.svg";
export const iconX = "/images/iconX.svg";
export const iconCaretDown = "/images/iconCaretDown.svg";
export const bcCalendar = "/images/bc-calendar.png";
export const bcBallotStatusAccepted = "/images/bc-ballotStatusAccepted.png";
export const bcBallotStatusRejected = "/images/bc-ballotStatusRejected.png";
export const bcBallotStatusDeclined = "/images/bc-ballotStatusDeclined.png";

export const resourceImage1 = "/images/Resource_Image_1.png";
export const resourceImage2 = "/images/Resource_Image_2.png";
export const resourceImage3 = "/images/SPLC_Monogram-black.png";

export const moreInfo = "/images/more-info.png";

export const instagram = "/images/instagram.svg";
export const twitter = "/images/twitter.svg";
export const facebook = "/images/facebook.svg";
export const linkedin = "/images/linkedin.svg";
export const threads = "/images/threads.svg";
export const youtube = "/images/youtube.svg";
export const tiktok = "/images/tiktok.svg";
export const candidateDefault = "/images/candidate_default.svg";
export const timWalz = "/images/Tim_Walz_Thumb.jpg";
export const jdVance = "/images/JD_Vance_Thumb.jpg";
export const mikeTerMaat_Thumb = "/images/Mike_Ter_Maat_Thumb.jpg";
export const butchWare_Thumb = "/images/Butch_Ware_Thumb.jpg";