import React from "react";
import { Container, Image, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { moreInfo } from "../../lib/images";
import { useTranslation } from "react-i18next";
import "./MoreInfo.styles.scss";

function MoreInfo() {
  const { t } = useTranslation();
  return (
    <Container className="more-info-container" fluid>
      <Row>
        <Col xs="12" sm="6">
          <h3>{t("looking_for_more")}</h3>
          <p>{t("visit_voter_reg")}</p>
          <Link to="/register-to-vote">
            <button type="button" className="btn-start btn-small btn-plan">
              {t("register_to_vote")}
            </button>
          </Link>
        </Col>
        <Col xs="12" sm="6" className="text-center">
          <Image src={moreInfo} alt="" />
        </Col>
      </Row>
    </Container>
  );
}

export default MoreInfo;
