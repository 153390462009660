import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import "./featuredRace.styles.scss";

function FeaturedRace(props) {
  return (
    <div className="collection-background featured-race-bg">
      <Container className="featured-race-container" fluid>
        <Row noGutters>
          <Col xs="12" md={{ span: 10, offset: 1 }}>
            <Container>
              <h2>Do you have a plan to vote in the January 5th runoff?</h2>
              <p>
                Voting is a powerful force for change, and this year there are
                several options for making your voice heard. Whether you plan to
                vote at home or in person, it's important to take a few minutes
                to figure out when, where, and how you'll be casting your ballot
                this year. We're here to help.
              </p>
              <br />
              <Link to="/voting-guide">
                <Button
                  className="download-button"
                  variant="danger"
                  type="submit"
                  style={{ display: "block", margin: "5px auto" }}
                >
                  {"Get voting info >"}
                </Button>
              </Link>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default FeaturedRace;
