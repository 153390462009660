import React, { useState, useContext, useEffect} from "react";
import { AddressCollection} from "../index";
import "./VoterGuide.style.scss";
import { Image, Form, Row, Col } from "react-bootstrap";
import { imageBallot, imageBallotMobile, iconHandRight,iconDropdownBlack } from "../../lib/images";
import {GlobalContext} from '../../context/GlobalState';
import { useMediaQuery } from "react-responsive";
import * as qs from 'qs'
import { useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";
import config from "../../lib/config";
import { Trans,useTranslation } from "react-i18next";
function VoterGuide(props) {
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const { state, addPayload } = useContext(GlobalContext);
  const [sourceParam, setSourceParam] = useState(
    sessionStorage.getItem(config.akSourceName)
  );
  const location = useLocation();
  const { resetGlobalState } = useContext(GlobalContext);
  const resetBallot = () => {
    resetGlobalState();
  };

  useEffect(() => {
    if (!sourceParam)
      setSourceParam(
        qs.parse(location.search, { ignoreQueryPrefix: true })[
          config.akSourceName
        ]
      ); // If no stored source exists, check QS. Use if found.
    if (props.isHome) {
      resetBallot();
    }
  }, [location, sourceParam, props.isHome]);
  function handleChangePhone(e){
    if (
      e.target.value.match("(^[0-9]+$|^$|^s$)") != null &&
      !(e.target.value.length > 10)
    ) {
      setPhone(e.target.value); 
    }
  }
  let candidatesJSON =
    state.candidateSelection &&
    state.candidateSelection.length > 0 &&
    encodeURI(JSON.stringify(state.candidateSelection))
      .replace(/\(/g, "%28")
      .replace(/\)/g, "%29");
  let candidatesQuery = candidatesJSON ? "&selections=" + candidatesJSON : "";
  let langQuery = i18n.language ? "&lang=" + i18n.language : "";
  let linkPdfQuery =
    "/" + state.query + "?pdf=view" + langQuery + candidatesQuery;
  let linkPdf = window.location.origin + linkPdfQuery;
  const renderQuestions = (curStep) => {
    switch (curStep) {
      case 1:
        return stepOne;
      case 2:
        return stepTwo;
      case 3:
        return stepThree;
      case 4:
        return stepFour;
      default:
        return stepOne;
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    var formData = new URLSearchParams();
    switch (i18n.language) {
      case "es":
        formData.append("page", config.actionKitBallotPage_es);
        break;
      case "kr":
        formData.append("page", config.actionKitBallotPage_kr);
        break;
      default:
        formData.append("page", config.actionKitBallotPage_en);
    }
    formData.append("email", email);
    if (firstName) formData.append("first_name", firstName);
    if (lastName) formData.append("last_name", lastName);
    if (phone) {
      formData.append("mobile_phone", phone);
      formData.append("texting_fieldname", "mobile_phone");
    }
    if (state.address) formData.append("address1", state.address);
    if (linkPdf) formData.append("action_pdf", linkPdf);
    if (linkPdf) formData.append("user_ballot_2024", linkPdf);
    if (sourceParam) formData.append("source", sourceParam);

    window.session.postSubscribeEmail(formData).then(
      function (res) {
        let payload = { email: true, step: 3, isOpen: true };
        addPayload(payload);
      },
      function (e) {}
    );
  };
  const stepOne = (
    <div className="stepone">
      <Row className="ctn-quiz-lp0102-rgtcontent1">
        <Col
          xs="6"
          md="8"
          className="d-flex flex-column justify-content-center pr-0"
        >
          {isMobile ? (
            <div>
              <div
                className={
                  "title title-large language-customization-" + i18n.language
                }
              >
                {t("georgia_time")}
              </div>
              <div
                className={
                  "sub-title sub-title-large language-customization-" +
                  i18n.language
                }
              >
                {t("make_history")}
              </div>
            </div>
          ) : (
            <div>
              <div className={"title language-customization-" + i18n.language}>
                {t("georgia_time")}
              </div>
              <div
                className={"sub-title language-customization-" + i18n.language}
              >
                {t("make_history")}
              </div>
            </div>
          )}
        </Col>
        <Col xs="5" md="4" className="d-none d-md-block image-ballot-center">
          <Image src={imageBallot} alt="" className="image-ballot" />
        </Col>
        <Col xs="6" md="4" className="d-md-none image-ballot-center">
          <Image src={imageBallotMobile} alt="" className="image-ballot" />
        </Col>
      </Row>
      {/* <div className="ctn-form">
        <div className="form-submit">
          <p className="leader">
            <strong>{t("enter_address_see")}</strong>
          </p>
          <AddressCollection />
        </div>
      </div> */}
    </div>
  );
  const stepTwo = (
    <div className="steptwo">
      <Row className="ctn-quiz-lp0102-rgtcontent1">
        <Col
          xs="6"
          md="7"
          className="d-flex flex-column justify-content-center pr-0"
        >
          <div className={"title language-customization-" + i18n.language}>
            {t("goal_meter_here_your")}
          </div>
          <div className={"sub-title language-customization-" + i18n.language}>
            {t("goal_meter_voter_guide")}
          </div>
          <div className="leader v-desktop">
            {t("goal_meter_personalize_it_further")}
          </div>
        </Col>
        <Col xs="6" md="5" className="d-none d-md-block">
          <Image src={imageBallot} alt="" className="image-ballot" />
        </Col>
        <Col xs="6" md="5" className="d-md-none">
          <Image src={imageBallotMobile} alt="" className="image-ballot" />
        </Col>
      </Row>
      <Row className="v-mobile">
        <div className="leader">{t("goal_meter_personalize_it_further")}</div>
      </Row>
      <div className="ctn-form">
        <Form
          id="email_personalized"
          onSubmit={(e) => handleSubmit(e)}
          className={
            isMobile ? "form-submit candidate-sendmail-mobile" : "form-submit"
          }
          style={{ display: "flex" }}
        >
          <Form.Control
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            className="userfield-input"
            placeholder={t("first_name") + "*"}
            required
          />
          <Form.Control
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className="userfield-input"
            placeholder={t("last_name") + "*"}
            required
          />
          <Form.Control
            type="email"
            name="email"
            id="id_email"
            className="userfield-input"
            placeholder={t("email") + "* "}
            required
            onChange={(e) => setEmail(e.target.value)}
          />
          <Form.Control
            type="tel"
            value={phone}
            name="cellphone"
            id="id_cellphone"
            onChange={(e) => handleChangePhone(e)}
            className="userfield-input"
            placeholder={t("cell_number") + " ¹"}
          />
          <button type="submit" className="full btn-send-email">
            {t("goal_meter_send_it_to_me")}
            <Image src={iconHandRight} alt="" />
          </button>
        </Form>
        <div>
          <p className="phone_note">
            <Trans i18nKey="providing_your_cell_phone">
              <a target="_blank" href="/privacy-policy"></a>
            </Trans>
          </p>
        </div>
      </div>
    </div>
  );
  const stepThree = (
    <div className="stepthree">
      <Row className="ctn-quiz-lp0102-rgtcontent1">
        <Col
          xs="5"
          md="6"
          className="d-flex flex-column justify-content-center pr-0"
        >
          <div className="title">{t("goal_meter_check_your")}</div>
          <div className="sub-title">{t("goal_meter_email")}</div>
          <div className="leader my-4">{t("goal_meter_you_sent_yourself")}</div>
        </Col>
        <Col xs="7" md="6" className="d-none d-md-block">
          <Image src={imageBallot} alt="" className="image-ballot" />
        </Col>
        <Col xs="7" md="6" className="d-md-none">
          <Image src={imageBallotMobile} alt="" className="image-ballot" />
        </Col>
      </Row>
      {/* <Row className="v-mobile">
        <div className="leader">You've sent yourself a copy of your personalized voter guide</div>
      </Row> */}
    </div>
  );
  const stepFour = (
    <div className="stepfour">
      <Row className="ctn-quiz-lp0102-rgtcontent1">
        <Col
          xs="6"
          md="7"
          className="d-flex flex-column justify-content-center pr-0"
        >
          <div className="title">{t("goal_meter_you_made_some")}</div>
          <div className="sub-title">{t("goal_meter_to_your_guide")}</div>
        </Col>
        <Col xs="6" md="5" className="d-none d-md-block">
          <Image src={imageBallot} alt="" className="image-ballot" />
        </Col>
        <Col xs="6" md="5" className="d-md-none">
          <Image src={imageBallotMobile} alt="" className="image-ballot" />
        </Col>
      </Row>
      <div className="ctn-form ctn-issues">
        <Link to={linkPdfQuery} target="_blank" className="button-submit btn">
          {t("goal_meter_download")}{" "}
          <Image src={iconDropdownBlack} alt="" className="icon-drop-down" />
        </Link>
      </div>
    </div>
  );
  return (
    <div className="ctn-voter-guid" id="personalize">
      {renderQuestions(state.step)}
    </div>
  );
}
export default VoterGuide;
