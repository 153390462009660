import React, { useState, useContext, useEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import ReactHtmlParser, { convertNodeToElement } from "react-html-parser";
import { renderToString } from "react-dom/server";
import { useMediaQuery } from "react-responsive";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Trans,useTranslation } from "react-i18next";
import { GlobalContext } from "../../context/GlobalState";
import config from "../../lib/config";
import { urlFor } from '../../sanityClient';
import {
  iconChecked,
  iconCheck,
  iconCheckMark,
  iconX,
  iconCaretDown,
  facebook,
  twitter,
  instagram,
  tiktok,
  youtube,
  threads,
  linkedin,
  candidateDefault,
  timWalz,
  jdVance,
  mikeTerMaat_Thumb,
  butchWare_Thumb,
} from "../../lib/images";

function toPlainText(blocks = []) {
  return (
    blocks
      // loop through each block
      .map((block) => {
        // if it's not a text block with children,
        // return nothing
        if (block._type !== "block" || !block.children) {
          return "";
        }
        // loop through the children spans, and join the
        // text strings
        return block.children.map((child) => child.text).join("");
      })
      // join the paragraphs leaving split by two linebreaks
      .join("\n\n")
      .replaceAll("data-for", "data-tooltip-id")
      .replaceAll("data-tip", "")
  );
}

const getInternationalizedContent = (field, language) => {
  // Map 'kr' to 'ko' for fetching from Sanity
  let mappedLanguage = language === 'kr' ? 'ko' : language;
  if (Array.isArray(field)) {
    const langContent = field.find((item) => item._key === mappedLanguage);
    return langContent ? langContent.value : null;
  }
  return null;
};

const extractTabText = (
  details,
  tab,
  t,
  hasBio,
  hasExperience,
  hasEndorsements,
  language
) => {
 if (details.name === "Claudia De la Cruz") {
    return `<p class="bg-emphasis-3rd">${t("3rd_bio_cruz")}</p>`;
  } else if (details.name === "Cornel West") {
    return `<p class="bg-emphasis-3rd">${t("3rd_bio_west")}</p>`;
  } else if (tab === "bio" && hasBio) {
    const content = getInternationalizedContent(details[tab], language);
    return content ? `<p>` + toPlainText(content) + `</p>` : "";
  } else if (tab === "endorsements" && hasEndorsements) {
    return `<p>` + toPlainText(details[tab]) + `</p>`;
  } else if (tab === "vp" && details.name === "Kamala Harris") {
    return `<p>${t("tim_walz_bio")}</p>`;
  } else if (tab === "vp" && details.name === "Donald Trump") {
    return `<p>${t("jd_vance_bio")}</p>`;
  } else if (tab === "vp" && details.name === "Chase Oliver") {
    return `<p>${t("mike_ter_maat_bio")}</p>`;
  } else if (tab === "vp" && details.name === "Jill Stein") {
    return `<p>${t("rudolph_ware_bio")}</p>`;
  } else if (tab === "experience" && hasExperience) {
    let expText = "";
    const jobTitleContent = getInternationalizedContent(
      details.job_title,
      language
    );
    if (jobTitleContent) {
      expText += `<p><strong>${t(
        "current_job"
      )}</strong> ${jobTitleContent}</p>`;
    }
    const personalEducationContent = getInternationalizedContent(
      details.personalEducation,
      language
    );
    if (personalEducationContent) {
      expText += `<p><strong>${t("personal_education")}</strong></p>
          <p><i>${toPlainText(personalEducationContent)}</i></p>`;
    }
    const experienceContent = getInternationalizedContent(
      details.experience,
      language
    );
    if (experienceContent) {
      expText += `<p>${toPlainText(experienceContent)}</p>`;
    }
    return expText;
  }

  // Check if the tab corresponds to an issue in the issues array
  const issuesTabsMap = config.issuesTabs;
  if (Array.isArray(details.issues) && issuesTabsMap.hasOwnProperty(tab)) {
    const issue = details.issues.find(issue => issue.issueName === issuesTabsMap[tab]);
    if (issue?.description?.length) {
      const issueContent = getInternationalizedContent(issue.description, language);
      return issueContent ? `<p>` + toPlainText(issueContent) + `</p>` : "";
    }
  }

  // If not, check if the tab corresponds to a voting history stance in the voting history array
  const votingHistoryTabsMap = config.votingHistoryTabs;
  if (Array.isArray(details.votingHistory) && votingHistoryTabsMap.hasOwnProperty(tab)) {

    const billsShown = 100
    const billsSectionID = Math.random().toString(36).substring(2)

    let previousVoteAs = null; // Track the previous bill's voteAs

    const billsInCategory = details.votingHistory
        .filter((bill) => (
            bill.billCategory === votingHistoryTabsMap[tab]
            && getInternationalizedContent(bill.votingRecord, 'en') !== '*DO NOT SHOW*'
        ))
        .map((bill, idx) => {
          if (bill.billCategory === votingHistoryTabsMap[tab]) {
            let voteAsDisplay = '';
            let localizedVoteAs = '';

            // Localize the voteAs field
            switch (bill.voteAs) {
              case 'US Congress':
                localizedVoteAs = t('us_congress');
                break;
              case 'State Senate':
                localizedVoteAs = t('state_senate');
                break;
              case 'State House':
                localizedVoteAs = t('state_house');
                break;
              default:
                localizedVoteAs = t('georgia_state'); // Default to Georgia State
            }

            if (bill.voteAs !== previousVoteAs) {
              voteAsDisplay = `<p>${t("voting_history_in")} ${localizedVoteAs}*</p>`;
              previousVoteAs = bill.voteAs; // Update the previous voteAs
            }
            // Get the localized bill name and description
            const localizedBillName = getInternationalizedContent(bill.billName, language);
            const localizedDescription = getInternationalizedContent(bill.description, language);
            const localizedVotingRecord = getInternationalizedContent(bill.votingRecord, language);

            return renderToString(
              <>
                {voteAsDisplay && (
                  <div dangerouslySetInnerHTML={{ __html: voteAsDisplay }} />
                )}
                <div
                  className={`bill-display ${
                    idx + 1 > billsShown ? "hidden" : ""
                  }`}
                  style={{ display: "flex" }}
                >
                  <div className="flex-shrink-0 flex-grow-0 mr-5">
                    <div className="voting-stance">
                      <Image
                        src={
                          getInternationalizedContent(
                            bill.votingRecord,
                            "en"
                          ).toLowerCase() === "voted no"
                            ? iconX
                            : getInternationalizedContent(
                                bill.votingRecord,
                                "en"
                              ).toLowerCase() === "excused" ||
                              getInternationalizedContent(
                                bill.votingRecord,
                                "en"
                              ).toLowerCase() === "not voting"
                            ? iconX
                            : iconCheckMark
                        }
                        className="voting-stance-icon"
                        alt=""
                      />
                      {localizedVotingRecord}
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <p className="bill-details">
                      <strong>
                        {localizedBillName || bill.billName} - {bill.billNumber}
                      </strong>
                      <br />
                      {localizedDescription
                        ? toPlainText(localizedDescription)
                        : "No description available"}
                    </p>
                  </div>
                </div>
              </>
            );
          }
        })
    return `<div id=${billsSectionID} class="bills">${billsInCategory.join('')}</div>`
  } else if (votingHistoryTabsMap.hasOwnProperty(tab)) {
    return `<div><p>${t("no_voting")}</p></div>`
  }

  return "";
};

const getTooltipData = (tooltipKey, sources) => {
  if (sources) {
    const source = sources.find((src) => src.tooltipKey === tooltipKey);
    return source ? source.url : null;
  } else {
    return null;
  }
};

function Candidate(props) {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  let details = props.details;
  let raceName = props.racename;
  let isRacePage = props.isRacePage;
  const { t, i18n } = useTranslation();
  const language = i18n.language || "en";  
  const { state, addSelection, removeSelection, setStep } =
    useContext(GlobalContext);
  let candidateSelection = state.candidateSelection;
  const [isSelected, setSelected] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  useEffect(() => {
    let findSelection = candidateSelection.find(
      (selection) => selection.race === raceName
    );
    if (findSelection) {
      if (findSelection.selection === details.name) {
        setSelected(true);
        setDisabled(false);
      } else {
        setSelected(false);
        setDisabled(true);
      }
    } else {
      setSelected(false);
      setDisabled(false);
    }
  }, [state, candidateSelection, details, raceName]);
  const toggleSelect = (selected) => {
    setSelected(!selected);
    let payload = { race: raceName, selection: details.name };
    if (selected) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "candidate_unselect",
        race: raceName,
        candidate: details.name,
      });
      removeSelection(payload);
    } else {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "candidate_select",
        race: raceName,
        candidate: details.name,
      });
      addSelection(payload);
      if (state.email) {
        setStep(4);
      }
    }
  };

  let disableSelection = state.step === 1 || isRacePage;

  // Check for Bio
  const bioContent = getInternationalizedContent(details["bio"], language);
  const hasBio = bioContent && toPlainText(bioContent) !== "";

  // Check for Experience
  const jobTitleContent = getInternationalizedContent(details.job_title, language);
  const personalEducationContent = getInternationalizedContent(
    details.personalEducation,
    language
  );
  const experienceContent = getInternationalizedContent(details.experience, language);
  const hasExperience =
    (typeof jobTitleContent === "string" && jobTitleContent.trim().length) ||
    (personalEducationContent && toPlainText(personalEducationContent) !== "") ||
    (experienceContent && toPlainText(experienceContent) !== "");

  // Check for Endorsements
  const detailsEndorsementsTab = details["endorsements"];
  const hasEndorsements = toPlainText(detailsEndorsementsTab) != "";

  const nonLocal = [
    "President",
    "Congressional District",
    "State Senate District",
    "State House District",
  ];

  const isLocal = !nonLocal.some((term) => raceName.includes(term));

  // Use tab text for your showDetails:
  let showDetails = extractTabText(
    details,
    props.tab,
    t,
    hasBio,
    hasExperience,
    hasEndorsements,
    language
  );

  let thumbImage = details.image && urlFor(details.image).url();
  if (!details.image) thumbImage = candidateDefault;
  if (props.tab === "vp") {
        switch (details.name) {
          case "Kamala Harris":
            thumbImage = timWalz;
            break;
          case "Donald Trump":
            thumbImage = jdVance;
            break;
          case "Chase Oliver":
            thumbImage = mikeTerMaat_Thumb;
            break;
          case "Jill Stein":
            thumbImage = butchWare_Thumb;
            break;
          case "Claudia De la Cruz":
            // thumbImage = jdVance;
            break;
          case "Cornel West":
            // thumbImage = jdVance;
            break;
          default:
            break;
        }
  }
  let options = {
    decodeEntities: true,
    transform: function transform(node, index) {
      if (node.type === "tag" && node.name === "sup") {
        const tooltipKey = node.attribs["data-tooltip-id"];
        var tooltipUrl = getTooltipData(tooltipKey, details.sources);

        switch (tooltipKey) {
          case "twalz-bio-1":
            tooltipUrl = "https://kamalaharris.com/meet-governor-tim-walz/";
            break;
          case "vance-bio-1":
            tooltipUrl = "https://www.vance.senate.gov/about/";
            break;
          case "mtermaat-bio-1":
            tooltipUrl = "https://miketermaat.com/about-the-campaign/";
            break;
          case "bware-bio-1":
            tooltipUrl = "https://www.jillstein2024.com/meet_butch";
            break;
          case "ccruz-bio-1":
            tooltipUrl = "https://www.fox5atlanta.com/news/georgia-supreme-court-votes-cornell-west-claudia-de-la-cruz-election";
            break;
          case "cwest-bio-1":
            tooltipUrl = "https://www.fox5atlanta.com/news/georgia-supreme-court-votes-cornell-west-claudia-de-la-cruz-election";
            break;
          default:
            break;
        }
        if (tooltipUrl) {
          return (
            <>
              <sup
                key={index}
                data-tooltip-id={tooltipKey}
                data-tooltip-content={tooltipUrl}
                data-tooltip-wrapper="span"
              >
                {node.children[0].data}
              </sup>
              <ReactTooltip
                id={tooltipKey}
                place="top"
                effect="solid"
                clickable
                delayHide={100}
              />
            </>
          );
        }
      }
      if (node.type === "tag" && node.name === "a") {
        node.attribs.target = "_blank";
        node.attribs.rel = "noopener";
        return convertNodeToElement(node, index, transform);
      }
    },
  };
  // let isRecommendation = config.featureRecommendation;
  let item = {};
  let mobileCandidate = (
    <Container
      className={`candidate-container candidate-mobile  ${
        isSelected ? "is-selected-candidate" : ""
      }`}
      fluid
    >
      <Container>
        <Row>
          <Col xs={{ span: 12, offset: 0 }}>
            <Row>
              <Col xs="4" style={{ paddingRight: "0px" }}>
                <div className="thumb-container">
                  {thumbImage && <Image src={thumbImage} alt={details.name} />}
                </div>
              </Col>

              <Col xs="8">
                <Row>
                  <Col xs="12" lg="6">
                    <Row style={{ padding: "10px 0px" }}>
                      <Col xs="12">
                        <h3>
                          {(() => {
                            if (props.tab === "vp") {
                              switch (details.name) {
                                case "Kamala Harris":
                                  return (
                                    t("tim_walz").toUpperCase() +
                                    " (" +
                                    details.party.charAt(0) +
                                    ")"
                                  );
                                case "Donald Trump":
                                  return (
                                    t("jd_vance").toUpperCase() +
                                    " (" +
                                    details.party.charAt(0) +
                                    ")"
                                  );
                                case "Chase Oliver":
                                  return (
                                    t("mike_ter_maat").toUpperCase() +
                                    " (" +
                                    details.party.charAt(0) +
                                    ")"
                                  );
                                case "Jill Stein":
                                  return (
                                    t("rudolph_ware").toUpperCase() +
                                    " (" +
                                    details.party.charAt(0) +
                                    ")"
                                  );
                                case "Claudia De la Cruz":
                                  return (
                                    details.name.toUpperCase() +
                                    " (" +
                                    details.party.charAt(0) +
                                    ")"
                                  );
                                case "Cornel West":
                                  return (
                                    details.name.toUpperCase() +
                                    " (" +
                                    details.party.charAt(0) +
                                    ")"
                                  );
                                default:
                                  return (
                                    details.name.toUpperCase() +
                                    " (" +
                                    details.party.charAt(0) +
                                    ")"
                                  );
                              }
                            } else {
                              return (
                                details.name.toUpperCase() +
                                " (" +
                                details.party.charAt(0) +
                                ")"
                              );
                            }
                          })()}
                        </h3>
                      </Col>
                      {props.tab === "bio" && (
                        <Col xs="12">
                          {
                            <>
                              {details.facebook_url ? (
                                <a target="_blank" href={details.facebook_url}>
                                  <Image
                                    src={facebook}
                                    alt=""
                                    className="icon-social"
                                  />
                                </a>
                              ) : (
                                ""
                              )}
                              {details.twitter_url ? (
                                <a target="_blank" href={details.twitter_url}>
                                  <Image
                                    src={twitter}
                                    alt=""
                                    className="icon-social"
                                  />
                                </a>
                              ) : (
                                ""
                              )}
                              {details.instagram_url ? (
                                <a target="_blank" href={details.instagram_url}>
                                  <Image
                                    src={instagram}
                                    alt=""
                                    className="icon-social"
                                  />
                                </a>
                              ) : (
                                ""
                              )}
                              {details.tiktok_url ? (
                                <a target="_blank" href={details.tiktok_url}>
                                  <Image
                                    src={tiktok}
                                    alt=""
                                    className="icon-social"
                                  />
                                </a>
                              ) : (
                                ""
                              )}
                              {details.youtube_url ? (
                                <a target="_blank" href={details.youtube_url}>
                                  <Image
                                    src={youtube}
                                    alt=""
                                    className="icon-social"
                                  />
                                </a>
                              ) : (
                                ""
                              )}
                              {details.threads_url ? (
                                <a target="_blank" href={details.threads_url}>
                                  <Image
                                    src={threads}
                                    alt=""
                                    className="icon-social"
                                  />
                                </a>
                              ) : (
                                ""
                              )}
                              {details.linkedin_url ? (
                                <a target="_blank" href={details.linkedin_url}>
                                  <Image
                                    src={linkedin}
                                    alt=""
                                    className="icon-social"
                                  />
                                </a>
                              ) : (
                                ""
                              )}
                            </>
                          }
                        </Col>
                      )}
                    </Row>
                  </Col>
                  <Col xs="12" lg="6">
                    <div
                      className="candidate-select clickable"
                      style={{ display: disableSelection ? "none" : "block" }}
                    >
                      {isDisabled ? (
                        <div
                          className="user-checked is-disabled"
                          onClick={() => toggleSelect(isSelected)}
                        >
                          <div className="user-checked-checkbox">
                            <Image
                              src={iconCheck}
                              alt=""
                              className="icon-user-unchecked"
                            />
                          </div>
                          <div className={"user-checked-title unselect"}>
                            {t("select_candidate")}
                          </div>
                        </div>
                      ) : (
                        <div
                          className="user-checked"
                          onClick={() => toggleSelect(isSelected)}
                        >
                          {isSelected ? (
                            <Image
                              src={iconChecked}
                              alt=""
                              className="icon-user-checked"
                            />
                          ) : null}
                          <div className="user-checked-checkbox">
                            <Image
                              src={iconCheck}
                              alt=""
                              className="icon-user-unchecked"
                            />
                          </div>
                          <div
                            className={
                              isSelected
                                ? "user-checked-title selected"
                                : item.status === 2
                                ? "user-checked-title unselect"
                                : "user-checked-title"
                            }
                          >
                            {isSelected
                              ? t("candidate_selected")
                              : t("select_candidate")}
                          </div>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={{ span: 12, offset: 0 }} style={{ textAlign: "left" }}>
            <div className="content-details">
              {showDetails === "" ? (
                <>
                  {!hasBio && !hasExperience && !hasEndorsements ? (
                    isLocal ? (
                      <p>
                        <Trans i18nKey="candidate_did_not_complete"></Trans>
                      </p>
                    ) : (
                      <p>
                        <Trans i18nKey="not_able_to_locate"></Trans>
                      </p>
                    )
                  ) : props.tab !== "endorsements" ? (
                    details.website ? (
                      <p>
                        <Trans i18nKey="to_learn_more">
                          <a
                            target="_blank"
                            href={details.website}
                            className="campaign-website"
                          ></a>
                        </Trans>
                      </p>
                    ) : isLocal ? (
                      <p>
                        <Trans i18nKey="candidate_did_not_complete"></Trans>
                      </p>
                    ) : (
                      <p>
                        <Trans i18nKey="not_able_to_locate"></Trans>
                      </p>
                    )
                  ) : (
                    <p>
                      <Trans i18nKey="not_able_source_endorsements"></Trans>
                    </p>
                  )}
                </>
              ) : (
                ReactHtmlParser(showDetails, options)
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
  let desktopCandidate = (
    <Container
      className={`candidate-container ${
        isSelected ? "is-selected-candidate" : ""
      }`}
      fluid
    >
      <Container>
        <Row>
          <Col
            xs={{ span: 8, offset: 2 }}
            md={{ span: 4, offset: 0 }}
            lg={{ span: 2, offset: 0 }}
          >
            <div className="thumb-container">
              {thumbImage && <Image src={thumbImage} alt={details.name} />}
            </div>
          </Col>
          <Col
            xs={12}
            md={8}
            style={{ textAlign: "left" }}
            lg={{ span: 10, offset: 0 }}
          >
            <Row>
              <Col xs="12" lg="6">
                <h3>
                  {(() => {
                    if (props.tab === "vp") {
                      switch (details.name) {
                        case "Kamala Harris":
                          return (
                            t("tim_walz").toUpperCase() +
                            " (" +
                            details.party.charAt(0) +
                            ")"
                          );
                        case "Donald Trump":
                          return (
                            t("jd_vance").toUpperCase() +
                            " (" +
                            details.party.charAt(0) +
                            ")"
                          );
                        case "Chase Oliver":
                          return (
                            t("mike_ter_maat").toUpperCase() +
                            " (" +
                            details.party.charAt(0) +
                            ")"
                          );
                        case "Jill Stein":
                          return (
                            t("rudolph_ware").toUpperCase() +
                            " (" +
                            details.party.charAt(0) +
                            ")"
                          );
                        case "Claudia De la Cruz":
                          return (
                            details.name.toUpperCase() +
                            " (" +
                            details.party.charAt(0) +
                            ")"
                          );
                        case "Cornel West":
                          return (
                            details.name.toUpperCase() +
                            " (" +
                            details.party.charAt(0) +
                            ")"
                          );
                        default:
                          return (
                            details.name.toUpperCase() +
                            " (" +
                            details.party.charAt(0) +
                            ")"
                          );
                      }
                    } else {
                      return (
                        details.name.toUpperCase() +
                        " (" +
                        details.party.charAt(0) +
                        ")"
                      );
                    }
                  })()}
                </h3>
              </Col>
              <Col xs="12" lg="6">
                <div
                  className="candidate-select clickable"
                  style={{ display: disableSelection ? "none" : "block" }}
                >
                  {isDisabled ? (
                    <div
                      className="user-checked is-disabled"
                      onClick={() => toggleSelect(isSelected)}
                    >
                      <div className="user-checked-checkbox">
                        <Image
                          src={iconCheck}
                          alt=""
                          className="icon-user-unchecked"
                        />
                      </div>
                      <div className={"user-checked-title unselect"}>
                        {t("select_candidate")}
                      </div>
                    </div>
                  ) : (
                    <div
                      className="user-checked"
                      onClick={() => toggleSelect(isSelected)}
                    >
                      {isSelected ? (
                        <Image
                          src={iconChecked}
                          alt=""
                          className="icon-user-checked"
                        />
                      ) : null}
                      <div className="user-checked-checkbox">
                        <Image
                          src={iconCheck}
                          alt=""
                          className="icon-user-unchecked"
                        />
                      </div>
                      <div
                        className={
                          isSelected
                            ? "user-checked-title selected"
                            : item.status === 2
                            ? "user-checked-title unselect"
                            : "user-checked-title"
                        }
                      >
                        {isSelected
                          ? t("candidate_selected")
                          : t("select_candidate")}
                      </div>
                    </div>
                  )}
                </div>
              </Col>
              {props.tab === "bio" && (
                <>
                  <Col xs="12" lg="6">
                    <div className="content-social">
                      {
                        <p>
                          {details.facebook_url ? (
                            <a target="_blank" href={details.facebook_url}>
                              <Image
                                src={facebook}
                                alt=""
                                className="icon-social"
                              />
                            </a>
                          ) : (
                            ""
                          )}
                          {details.twitter_url ? (
                            <a target="_blank" href={details.twitter_url}>
                              <Image
                                src={twitter}
                                alt=""
                                className="icon-social"
                              />
                            </a>
                          ) : (
                            ""
                          )}
                          {details.instagram_url ? (
                            <a target="_blank" href={details.instagram_url}>
                              <Image
                                src={instagram}
                                alt=""
                                className="icon-social"
                              />
                            </a>
                          ) : (
                            ""
                          )}
                          {details.tiktok_url ? (
                            <a target="_blank" href={details.tiktok_url}>
                              <Image
                                src={tiktok}
                                alt=""
                                className="icon-social"
                              />
                            </a>
                          ) : (
                            ""
                          )}
                          {details.youtube_url ? (
                            <a target="_blank" href={details.youtube_url}>
                              <Image
                                src={youtube}
                                alt=""
                                className="icon-social"
                              />
                            </a>
                          ) : (
                            ""
                          )}
                          {details.threads_url ? (
                            <a target="_blank" href={details.threads_url}>
                              <Image
                                src={threads}
                                alt=""
                                className="icon-social"
                              />
                            </a>
                          ) : (
                            ""
                          )}
                          {details.linkedin_url ? (
                            <a target="_blank" href={details.linkedin_url}>
                              <Image
                                src={linkedin}
                                alt=""
                                className="icon-social"
                              />
                            </a>
                          ) : (
                            ""
                          )}
                        </p>
                      }
                    </div>
                  </Col>
                </>
              )}
            </Row>
            <Row>
              <Col xs="12" lg="12">
                <Row style={{ padding: "10px 0px" }}>
                  <Col xs="12">
                    <div className="content-details">
                      {showDetails === "" ? (
                        <>
                          {!hasBio && !hasExperience && !hasEndorsements ? (
                            isLocal ? (
                              <p>
                                <Trans i18nKey="candidate_did_not_complete"></Trans>
                              </p>
                            ) : (
                              <p>
                                <Trans i18nKey="not_able_to_locate"></Trans>
                              </p>
                            )
                          ) : props.tab !== "endorsements" ? (
                            details.website ? (
                              <p>
                                <Trans i18nKey="to_learn_more">
                                  <a
                                    target="_blank"
                                    href={details.website}
                                    className="campaign-website"
                                  ></a>
                                </Trans>
                              </p>
                            ) : isLocal ? (
                              <p>
                                <Trans i18nKey="candidate_did_not_complete"></Trans>
                              </p>
                            ) : (
                              <p>
                                <Trans i18nKey="not_able_to_locate"></Trans>
                              </p>
                            )
                          ) : (
                            <p>
                              <Trans i18nKey="not_able_source_endorsements"></Trans>
                            </p>
                          )}
                        </>
                      ) : (
                        ReactHtmlParser(showDetails, options)
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* <div className="content-details">
              <>
                {details.website ? (
                  <p>
                    <Trans i18nKey="to_learn_more">
                      <a
                        target="_blank"
                        href={details.website}
                        className="campaign-website"
                      ></a>
                    </Trans>
                  </p>
                ) : (
                  ""
                )}
              </>
            </div> */}
          </Col>
        </Row>
      </Container>
    </Container>
  );
  return (
    <div>
      {isMobile ? mobileCandidate : desktopCandidate}
      <ReactTooltip
        className="citation-tooltip"
        place="top"
        type="dark"
        effect="solid"
        delayHide={100}
        clickable
      />
    </div>
  );
}

export default Candidate;
