import React, { useState, useEffect } from "react";
import { Image, Container } from "react-bootstrap";
// import config from "../../lib/config";
import "./Quiz.style.scss";
import QuizCard from "./QuizCard.component";
import MediaQuery from "react-responsive";
import { Carousel } from "react-responsive-carousel";
import { iconStartOver } from "../../lib/images";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useTranslation } from "react-i18next";
let origin = window.location.origin;

function Quiz() {
  const { t } = useTranslation();
  const dataTemp = [
    {
      id: 1,
      isAnswered: false, isShowFact: false, isShowMyth: false, imgUrl: 'images/Learn-More-1.png',
      title:'voting_by_mail_is_just',
      fact: 'that_right',
      myth: 'there_is_no_evidence',
      twDes: "Did you know that voting by mail is just as secure as other forms of voting? Learn more about voting in Georgia at #TheGeorgiaVoterGuide ",
      twShare: origin + "/learn-more"
    },
    {
      id: 2,
      isAnswered: false,
      isShowFact: false,
      isShowMyth: false,
      imgUrl: "images/Learn-More-2.png",
      title: "voting_early_before_election_day",
      fact: "early_voters_often_experience",
      myth: "be_wary_of_any_media",
      twDes:
        "Did you know that early voters often experience shorter lines? Learn more about the different ways you can cast a vote at #TheGeorgiaVoterGuide ",
      twShare: origin + "/learn-more",
    },
    {
      id: 3,
      isAnswered: false,
      isShowFact: false,
      isShowMyth: false,
      imgUrl: "images/Learn-More-3.png",
      title: "voting_on_election_day_is_still",
      fact: "yep_you_got_it",
      myth: "not_quite",
      twDes:
        "Did you know that voting on Election Day is still the most popular way to cast a vote? Get ready for the midterm elections at #TheGeorgiaVoterGuide ",
      twShare: origin + "/learn-more",
    },
  ];
  const [dataQuiz, setDataQuiz] = useState(dataTemp);
  const [isStartOver, setIsStartOver] = useState(false);
  const [cardIndex, setCardIndex] = useState(0);

  const updateStartOver = () => {
    setIsStartOver(true);
  };
  const toggleShowFact = (idx) => {
    const quizClone = JSON.parse(JSON.stringify(dataQuiz));
    quizClone[idx].isShowMyth = false;
    quizClone[idx].isShowFact = !quizClone[idx].isShowFact;
    quizClone[idx].isAnswered = true;
    setDataQuiz(quizClone);
  };
  const toggleShowMyth = (idx) => {
    const quizClone = JSON.parse(JSON.stringify(dataQuiz));
    quizClone[idx].isShowFact = false;
    quizClone[idx].isShowMyth = !quizClone[idx].isShowMyth;
    quizClone[idx].isAnswered = true;
    setDataQuiz(quizClone);
  };
  const resetQuiz = () => {
    const quizClone = JSON.parse(JSON.stringify(dataQuiz));
    quizClone.forEach((x) => (x.isShowFact = false));
    quizClone.forEach((x) => (x.isShowMyth = false));
    quizClone.forEach((x) => (x.isAnswered = false));
    setDataQuiz(quizClone);
  };
  useEffect(() => {
    var isExistSome = dataQuiz.some((x) => x.isShowFact || x.isShowMyth);
    setIsStartOver(isExistSome);
  }, [dataQuiz]);
  return (
    <div className="ctn-quiz">
      <div className="quiz-title">{t("how_well_do")}</div>
      <MediaQuery maxWidth={991}>
        <div className="carousel-wrapper quiz-content">
          <Container>
            <Carousel
              showThumbs={false}
              emulateTouch
              onChange={(e) => setCardIndex(e)}
            >
              {dataQuiz.map((item, idx) => (
                <QuizCard
                  selected={cardIndex === idx}
                  item={item}
                  key={item.id}
                  updateStartOver={updateStartOver}
                  toggleShowFact={() => toggleShowFact(idx)}
                  toggleShowMyth={() => toggleShowMyth(idx)}
                />
              ))}
            </Carousel>
          </Container>
        </div>
      </MediaQuery>
      <div className="quiz-content version-desktop">
        {dataQuiz.map((item, idx) => (
          <QuizCard
            selected={true}
            item={item}
            key={item.id}
            updateStartOver={updateStartOver}
            toggleShowFact={() => toggleShowFact(idx)}
            toggleShowMyth={() => toggleShowMyth(idx)}
          />
        ))}
      </div>
      {isStartOver === false ? (
        ""
      ) : (
        <div className="quiz-start-over clickable" onClick={resetQuiz}>
          {t("start_over")}{" "}
          <Image
            src={iconStartOver}
            className="btn-startover"
            alt="Quiz - Start Over"
          />
        </div>
      )}
    </div>
  );
}
export default Quiz;
