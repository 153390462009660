import React, { createContext, useReducer, useEffect } from "react";
import AppReducer from "./AppReducer";

const initialState = {
	ballotList: [],
	candidateSelection: [],
	address: "",
	email: false,
	step: 1,
	query: "",
	isOpen: false,
};

function getInitialState() {
	const globalState = localStorage.getItem("globalState");
	return globalState ? JSON.parse(globalState) : initialState;
}

export const GlobalContext = createContext(getInitialState());

export const GlobalProvider = ({ children }) => {
	const [state, dispatch] = useReducer(AppReducer, getInitialState());
	let newState = { ...state };
	// Actions for changing state
	useEffect(() => {
		localStorage.setItem("globalState", JSON.stringify(state));
	}, [state]);

	function addItemToList(item) {
		newState.ballotList = item;
		dispatch({
			type: "ADD_ITEM",
			payload: newState,
		});
	}	
	function removeItemFromList(item) {
		newState.ballotList = item;
		dispatch({
			type: "REMOVE_ITEM",
			payload: newState,
		});
	}
	function addAddress(item) {
		newState.address = item;
		dispatch({
			type: "ADD_ADDRESS",
			payload: newState,
		});
	}
	function setStep(item) {
		newState.step = item;
		dispatch({
			type: "SET_STEP",
			payload: newState,
		});
	}
	function addSelection(item) {
		let candidateSelection = newState.candidateSelection;
		if (candidateSelection && candidateSelection.length === 0) {
		 (newState.candidateSelection = [item])
		} else { 
			let isNew = false;
			const newArr = candidateSelection.map(element => {
				if (element.race === item.race) {
					isNew = true;
					return item;
				}
				return element;
			});
			if (!isNew) {
				newState.candidateSelection.push(item);
			} else {
				newState.candidateSelection = newArr;
			}

		}
		dispatch({
			type: "ADD_SELECTION",
			payload: newState,
		});
	}
	function removeSelection(item) {
		newState.candidateSelection = newState.candidateSelection.filter(i => JSON.stringify(i) !== JSON.stringify(item));
		dispatch({
			type: "REMOVE_SELECTION",
			payload: newState,
		});
	}
	function addPayload(item) {
		newState.ballotList = item.ballotList || state.ballotList;
		newState.address = item.address || state.address;
		newState.email = item.email || state.email;
		newState.step = item.step || state.step;
		newState.candidateSelection =
			item.candidateSelection || state.candidateSelection;
		newState.query = item.query || state.query;
		newState.isOpen = item.isOpen || state.isOpen;
		dispatch({
			type: "ADD_PAYLOAD",
			payload: newState,
		});
	}
	function resetGlobalState() {
		newState = {
			ballotList: [],
			candidateSelection: [],
			address: "",
			email: false,
			step: 1,
			query: "",
			isOpen: false,
		};
		dispatch({
			type: "RESET_STATE",
			payload: newState,
		});
	}
	function openGoalMeter(item) {
		newState.isOpen = item;
		dispatch({
			type: "OPEN_GOAL",
			payload: newState,
		});
	}
	return (
		<GlobalContext.Provider
			value={{
				state,
				addItemToList,
				removeItemFromList,
				addAddress,
				setStep,
				addPayload,
				addSelection,
				removeSelection,
				resetGlobalState,
				openGoalMeter,
			}}>
			{children}
		</GlobalContext.Provider>
	);
};
