import React from "react";
import { CandidatePanel } from "../index";
import { Container } from "react-bootstrap";
import "./ballot.styles.scss";

function Ballots(props) {
  // const orderGuide = [
  //   "governor",
  //   "senate",
  //   "lieutenant_governor",
  //   "secretary_of_state",
  //   "attorney_general",
  //   "labor_commissioner",
  //   "agriculture_commissioner",
  //   "insurance_fire_commissioner",
  //   "state_school_superintendent",
  //   "us_congress",
  //   "state_senate",
  //   "state_house",
  // ];

  // const orderHome = ["president", "senate", "gps_commission"];
  // function sortRace(ballot) {
  //   return ballot.sort((a, b) => a.order - b.order);
  // }
  let filterPendingBallot = props.ballots && props.ballots.slice();
  // let orderedRace = sortRace(filterPendingBallot);
  // let ballots = orderedRace;
  let ballots = filterPendingBallot;
  let ballotDisplay = [];
  ballots &&
    ballots.map(function (ballot, i) {
      return ballotDisplay.push(
        <CandidatePanel key={i} details={ballot} />,
      );
    });
  
  return (
    <Container fluid>
      {ballotDisplay}
    </Container>
  );
}

export default Ballots;
