import React from "react";
import { Image, Container, Row, Col } from "react-bootstrap";
import "./ballotCuring.style.scss";
import {
  starIconOrange,
  iconHandOrange,
  iconQuizLP04,
  iconWarning,
  resourceImage1,
  bcCalendar,
  bcBallotStatusAccepted,
  bcBallotStatusRejected,
  bcBallotStatusDeclined,
} from "../../lib/images";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

function BallotCuring() {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const { t } = useTranslation();
  function goToAnchor(anchor) {
    var loc = document.location.toString().split("#")[0];
    document.location = loc + "#" + anchor;
    return false;
  }
  return (
    <div className="ctn-quiz-lp04 ballot-curing">
      <div className="quiz-lp-top-header">
        <Image src={starIconOrange} className="icon-star-left" alt="" />
        <div className="top-header-title">
          <p>{t("bc_information_only_applies")}</p>
        </div>
        <Image src={starIconOrange} className="icon-star-right" alt="" />
      </div>
      <Container className="bc-section">
        <Row>
          <Col xs="12" md="6" className="col-left">
            <div className="title">{t("bc_check_vote")}</div>
            <div className="text-content">
              {" "}
              <Trans i18nKey="bc_if_already_voted">
                <a
                  href="https://mvp.sos.ga.gov/s/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                </a>
              </Trans>
            </div>
            <div className="text-content">{t("bc_if_ballot_accepted")}</div>
          </Col>
          <Col xs="12" md="6" className="col-right">
            <Image src={bcBallotStatusAccepted} />
          </Col>{" "}
        </Row>
      </Container>

      <Container className="bc-section">
        <Row>
          <Col xs="12" md="6" className="col-left">
            <div className="div-warning">
              <Image src={iconWarning} className="icon-warning" />
              <Trans i18nKey="bc_important_curing">
                <div className="warning-title"></div>
                <span className="bg-emphasis"></span>
              </Trans>
            </div>
            <div className="text-content">
              <Trans i18nKey="bc_if_ballot_not_accepted">
                <span className="bg-emphasis-red"></span>
              </Trans>
            </div>
            <div className="text-content">
              <ul className="dashed">
                <li>
                  {" "}
                  <Trans i18nKey="bc_to_make_corrections">
                    <a
                      href="https://mvp.sos.ga.gov/s/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                    </a>
                  </Trans>
                </li>
                <li>
                  {" "}
                  <Trans i18nKey="bc_if_unclear">
                    <a
                      href="https://mvp.sos.ga.gov/s/county-election-offices"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                    </a>
                  </Trans>
                </li>
              </ul>
            </div>
            <div className="star-warning">
              <div className="flex">
                <Image
                  src={starIconOrange}
                  className="icon-star-warning"
                  alt=""
                />
              </div>
              <div>
                <p>
                  <Trans i18nKey="bc_if_ballot_flagged">
                    <i></i>
                  </Trans>
                </p>
              </div>
            </div>
          </Col>
          <Col xs="12" md="6" className="col-right">
            <Image src={bcBallotStatusDeclined} />
            <Image src={bcBallotStatusRejected} />
          </Col>{" "}
        </Row>
      </Container>
      <Container className="bc-section"></Container>
    </div>
  );
}
export default BallotCuring;
