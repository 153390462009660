 // eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
   switch(action.type) {
       case 'ADD_ITEM':
           return action.payload
       case 'REMOVE_ITEM':
           return action.payload
       default:
        return action.payload
    }
}