import React from "react";
import { Image, Collapse, Container, Row, Col } from "react-bootstrap";
import "../QuizLandingPage/QuizLandingPage.style.scss";
import {
  starIconOrange,
  iconPlus,
  iconMinus,
  iconWarning,
  iconHandOrange,
} from "../../lib/images";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

function OfficeDescription() {
  const { t } = useTranslation();
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  // const [open5, setOpen5] = useState(false);
  // const [open6, setOpen6] = useState(false);
  // const [open7, setOpen7] = useState(false);
  const [open8, setOpen8] = useState(false);
  const [open9, setOpen9] = useState(false);
  const [open10, setOpen10] = useState(false);
  const [open11, setOpen11] = useState(false);
function goToAnchor(anchor) {
    var loc = document.location.toString().split("#")[0];
    document.location = loc + "#" + anchor;
    return false;
  }

  return (
    <div className="ctn-quiz-lp03 office-description">
      <div className="quiz-lp-top-header">
        <Image src={starIconOrange} className="icon-star-left" alt="" />
        <div className="top-header-title">
          <p>{t("learn_more_offices")}</p>
        </div>
        <Image src={starIconOrange} className="icon-star-right" alt="" />
      </div>
      <div className="quiz-lp-btn-action">
        <button
          type="button"
          className="btn-action-item"
          onClick={() => {
            setOpen1("true");
            goToAnchor("president");
            return false;
          }}
        >
          {t("office_president")}
        </button>
        <button
          type="button"
          className="btn-action-item"
          onClick={() => {
            setOpen2("true");
            goToAnchor("congressperson");
            return false;
          }}
        >
          {t("office_congressperson")}
        </button>
        <button
          type="button"
          className="btn-action-item"
          onClick={() => {
            setOpen3("true");
            goToAnchor("state_senator");
            return false;
          }}
        >
          {t("office_state_senator")}
        </button>
        <button
          type="button"
          className="btn-action-item"
          onClick={() => {
            setOpen4("true");
            goToAnchor("state_house_representative");
            return false;
          }}
        >
          {t("office_state_house_representative")}
        </button>
        {/* <button
          type="button"
          className="btn-action-item"
          onClick={() => {
            setOpen5("true");
            goToAnchor("mayor");
            return false;
          }}
        >
          {t("office_mayor")}
        </button>
        <button
          type="button"
          className="btn-action-item"
          onClick={() => {
            setOpen6("true");
            goToAnchor("mayor_pro_tem");
            return false;
          }}
        >
          {t("office_mayor_pro_tem")}
        </button>
        <button
          type="button"
          className="btn-action-item"
          onClick={() => {
            setOpen7("true");
            goToAnchor("city_council");
            return false;
          }}
        >
          {t("office_city_council")}
        </button> */}
        <button
          type="button"
          className="btn-action-item"
          onClick={() => {
            setOpen8("true");
            goToAnchor("county_commissioner");
            return false;
          }}
        >
          {t("office_county_commissioner")}
        </button>
        <button
          type="button"
          className="btn-action-item"
          onClick={() => {
            setOpen9("true");
            goToAnchor("boe");
            return false;
          }}
        >
          {t("office_boe")}
        </button>
        <button
          type="button"
          className="btn-action-item"
          onClick={() => {
            setOpen10("true");
            goToAnchor("da");
            return false;
          }}
        >
          {t("office_da")}
        </button>
        <button
          type="button"
          className="btn-action-item"
          onClick={() => {
            setOpen11("true");
            goToAnchor("at_large");
            return false;
          }}
        >
          {t("office_at_large")}
        </button>
      </div>
      <div className="collape-item">
        <div className="collape-item-top" id="president">
          <div className="collape-item-lable">{t("office_president")}</div>
          <button
            type="button"
            className="collape-item-button"
            onClick={() => setOpen1(!open1)}
            aria-controls="collapse-text-05"
            aria-expanded={open1}
          >
            {open1 ? (
              <Image src={iconMinus} className="icon-minus" />
            ) : (
              <Image src={iconPlus} />
            )}
          </button>
        </div>
        <Collapse in={open1}>
          <div id="collapse-text-05" className="collapse-text-content">
            <div className="collapse-text-item">
              <div className="col-left">
                <div className="text-content">{t("office_president_desc")}</div>
              </div>
            </div>
          </div>
        </Collapse>
      </div>

      <div className="collape-item">
        <div className="collape-item-top" id="congressperson">
          <div className="collape-item-lable">{t("office_congressperson")}</div>
          <button
            type="button"
            className="collape-item-button"
            onClick={() => setOpen2(!open2)}
            aria-controls="collapse-text-05"
            aria-expanded={open2}
          >
            {open2 ? (
              <Image src={iconMinus} className="icon-minus" />
            ) : (
              <Image src={iconPlus} />
            )}
          </button>
        </div>
        <Collapse in={open2}>
          <div id="collapse-text-05" className="collapse-text-content">
            <div className="collapse-text-item">
              <div className="col-left">
                <div className="text-content">
                  {t("office_congressperson_desc")}
                </div>
              </div>
            </div>
          </div>
        </Collapse>
      </div>

      <div className="collape-item">
        <div className="collape-item-top" id="state_senator">
          <div className="collape-item-lable">{t("office_state_senator")}</div>
          <button
            type="button"
            className="collape-item-button"
            onClick={() => setOpen3(!open3)}
            aria-controls="collapse-text-05"
            aria-expanded={open3}
          >
            {open3 ? (
              <Image src={iconMinus} className="icon-minus" />
            ) : (
              <Image src={iconPlus} />
            )}
          </button>
        </div>
        <Collapse in={open3}>
          <div id="collapse-text-05" className="collapse-text-content">
            <div className="collapse-text-item">
              <div className="col-left">
                <div className="text-content">
                  {t("office_state_senator_desc")}
                </div>
              </div>
            </div>
          </div>
        </Collapse>
      </div>

      <div className="collape-item">
        <div className="collape-item-top" id="state_house_representative">
          <div className="collape-item-lable">
            {t("office_state_house_representative")}
          </div>
          <button
            type="button"
            className="collape-item-button"
            onClick={() => setOpen4(!open4)}
            aria-controls="collapse-text-05"
            aria-expanded={open4}
          >
            {open4 ? (
              <Image src={iconMinus} className="icon-minus" />
            ) : (
              <Image src={iconPlus} />
            )}
          </button>
        </div>
        <Collapse in={open4}>
          <div id="collapse-text-05" className="collapse-text-content">
            <div className="collapse-text-item">
              <div className="col-left">
                <div className="text-content">
                  {t("office_state_house_representative_desc")}
                </div>
              </div>
            </div>
          </div>
        </Collapse>
      </div>

      {/* <div className="collape-item">
        <div className="collape-item-top" id="mayor">
          <div className="collape-item-lable">{t("office_mayor")}</div>
          <button
            type="button"
            className="collape-item-button"
            onClick={() => setOpen5(!open5)}
            aria-controls="collapse-text-05"
            aria-expanded={open5}
          >
            {open5 ? (
              <Image src={iconMinus} className="icon-minus" />
            ) : (
              <Image src={iconPlus} />
            )}
          </button>
        </div>
        <Collapse in={open5}>
          <div id="collapse-text-05" className="collapse-text-content">
            <div className="collapse-text-item">
              <div className="col-left">
                <div className="text-content">{t("office_mayor_desc")}</div>
              </div>
            </div>
          </div>
        </Collapse>
      </div>

      <div className="collape-item">
        <div className="collape-item-top" id="mayor_pro_tem">
          <div className="collape-item-lable">{t("office_mayor_pro_tem")}</div>
          <button
            type="button"
            className="collape-item-button"
            onClick={() => setOpen6(!open6)}
            aria-controls="collapse-text-06"
            aria-expanded={open6}
          >
            {open6 ? (
              <Image src={iconMinus} className="icon-minus" />
            ) : (
              <Image src={iconPlus} />
            )}
          </button>
        </div>
        <Collapse in={open6}>
          <div id="collapse-text-06" className="collapse-text-content">
            <div className="collapse-text-item">
              <div className="col-left">
                <div className="text-content">
                  {t("office_mayor_pro_tem_desc")}{" "}
                </div>
              </div>
            </div>
          </div>
        </Collapse>
      </div>

      <div className="collape-item">
        <div className="collape-item-top" id="city_council">
          <div className="collape-item-lable">{t("office_city_council")}</div>
          <button
            type="button"
            className="collape-item-button"
            onClick={() => setOpen7(!open7)}
            aria-controls="collapse-text-07"
            aria-expanded={open7}
          >
            {open7 ? (
              <Image src={iconMinus} className="icon-minus" />
            ) : (
              <Image src={iconPlus} />
            )}
          </button>
        </div>
        <Collapse in={open7}>
          <div id="collapse-text-07" className="collapse-text-content">
            <div className="collapse-text-item">
              <div className="col-left">
                <div className="text-content">
                  {t("office_city_council_desc")}{" "}
                </div>
              </div>
            </div>
          </div>
        </Collapse>
      </div> */}

      <div className="collape-item">
        <div className="collape-item-top" id="county_commissioner">
          <div className="collape-item-lable">
            {t("office_county_commissioner")}
          </div>
          <button
            type="button"
            className="collape-item-button"
            onClick={() => setOpen8(!open8)}
            aria-controls="collapse-text-08"
            aria-expanded={open8}
          >
            {open8 ? (
              <Image src={iconMinus} className="icon-minus" />
            ) : (
              <Image src={iconPlus} />
            )}
          </button>
        </div>
        <Collapse in={open8}>
          <div id="collapse-text-08" className="collapse-text-content">
            <div className="collapse-text-item">
              <div className="col-left">
                <div className="text-content">
                  {t("office_county_commissioner_desc")}{" "}
                </div>
              </div>
            </div>
          </div>
        </Collapse>
      </div>

      <div className="collape-item">
        <div className="collape-item-top" id="boe">
          <div className="collape-item-lable">{t("office_boe")}</div>
          <button
            type="button"
            className="collape-item-button"
            onClick={() => setOpen9(!open9)}
            aria-controls="collapse-text-09"
            aria-expanded={open9}
          >
            {open9 ? (
              <Image src={iconMinus} className="icon-minus" />
            ) : (
              <Image src={iconPlus} />
            )}
          </button>
        </div>
        <Collapse in={open9}>
          <div id="collapse-text-09" className="collapse-text-content">
            <div className="collapse-text-item">
              <div className="col-left">
                <div className="text-content">{t("office_boe_desc")} </div>
              </div>
            </div>
          </div>
        </Collapse>
      </div>

      <div className="collape-item">
        <div className="collape-item-top" id="da">
          <div className="collape-item-lable">{t("office_da")}</div>
          <button
            type="button"
            className="collape-item-button"
            onClick={() => setOpen10(!open10)}
            aria-controls="collapse-text-10"
            aria-expanded={open10}
          >
            {open10 ? (
              <Image src={iconMinus} className="icon-minus" />
            ) : (
              <Image src={iconPlus} />
            )}
          </button>
        </div>
        <Collapse in={open10}>
          <div id="collapse-text-10" className="collapse-text-content">
            <div className="collapse-text-item">
              <div className="col-left">
                <div className="text-content">{t("office_da_desc")} </div>
              </div>
            </div>
          </div>
        </Collapse>
      </div>
      <div className="collape-item">
        <div className="collape-item-top" id="at_large">
          <div className="collape-item-lable">{t("office_at_large")}</div>
          <button
            type="button"
            className="collape-item-button"
            onClick={() => setOpen11(!open11)}
            aria-controls="collapse-text-11"
            aria-expanded={open11}
          >
            {open11 ? (
              <Image src={iconMinus} className="icon-minus" />
            ) : (
              <Image src={iconPlus} />
            )}
          </button>
        </div>
        <Collapse in={open11}>
          <div id="collapse-text-11" className="collapse-text-content">
            <div className="collapse-text-item">
              <div className="col-left">
                <div className="text-content">{t("office_at_large_desc")} </div>
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
}
export default OfficeDescription;
