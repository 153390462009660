import React, { useState } from "react";
import { Container, Col, Row, Form, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import ReactGA from "react-ga";

import config from "../../lib/config";
import "./EmailCollection.styles.scss";

export default function EmailForm({
  buttonText,
  isHeader,
  oneRow,
  showPrivacy,
  toDownload,
}) {
  buttonText = buttonText || "Create your guide >";
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("INITIAL");
  const [privacy, setPrivacy] = useState("Y");
  let linkPdf = window.location.href + "?pdf=view";
  let linkRedirect = useLocation().pathname + "?pdf=view";
  function handleSubmit(e) {
    e.preventDefault();
    let payload = {
      page: isHeader ? config.actionKitPageHeader : config.actionKitPage,
      first_name: firstName,
      last_name: lastName,
      email: email,
      user_pdf_link: linkPdf,
      user_privacy_check: privacy,
    };
    window.session.postSubscribeEmail(payload).then(function (response) {
      if (response && response.lookupError) {
        // setError(response.lookupError)
      } else {
        setStatus("SUCCESS");
        if (typeof window !== "undefined") {
          if (window.gtag !== null) {
            isHeader
              ? ReactGA.event({
                  category: "Engagement",
                  action: "FormSubmission",
                  label: "Header Form",
                })
              : ReactGA.event({
                  category: "Engagement",
                  action: "FormSubmission",
                  label: "PDF Request Form",
                });
          }
        }
      }
    });
  }
  const headerForm = (
    <Container>
      <Row>
        <Col
          xs={6}
          md={oneRow ? "3" : "6"}
          lg={oneRow ? "3" : "6"}
          className="form-col"
        >
          <Form.Label className="secondName">First Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="First name"
            className="name-input"
            onChange={(e) => setFirstName(e.target.value)}
          />
        </Col>
        <Col
          xs={6}
          md={oneRow ? "3" : "6"}
          lg={oneRow ? "3" : "6"}
          className="form-col"
        >
          <Form.Label className="secondName">Last Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Last name"
            onChange={(e) => setLastName(e.target.value)}
          />
        </Col>
        <Col
          xs={12}
          md={oneRow ? "3" : "6"}
          lg={oneRow ? "3" : "7"}
          className="form-col"
        >
          <Form.Label className="secondName">Email Address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Email address"
            onChange={(e) => setEmail(e.target.value)}
            className={oneRow ? "email-input" : ""}
            required
          />
        </Col>
        <Col
          xs={12}
          md={oneRow ? "3" : "6"}
          lg={oneRow ? "3" : "5"}
          className="form-col"
        >
          <button type="submit" className="submit-button">
            {buttonText}
          </button>
        </Col>
      </Row>

      {showPrivacy ? (
        <Row>
          <Form.Check
            onChange={(e) =>
              privacy === "Y" ? setPrivacy("N") : setPrivacy("Y")
            }
            value="Y"
            checked={privacy === "Y"}
            type="checkbox"
            label="Let me know about important election updates."
          />
        </Row>
      ) : null}
    </Container>
  );
  return (
    <div className="signup-form">
      {status === "SUCCESS" ? (
        toDownload ? (
          <Button
            className="download-button"
            variant="danger"
            type="submit"
            style={{ display: "block", margin: "5px auto" }}
          >
            <Link to={linkRedirect} target="_blank">
              Download PDF
            </Link>
          </Button>
        ) : (
          <div style={{ color: "white", margin: "auto", textAlign: "center" }}>
            Thank you!
          </div>
        )
      ) : (
        <Form onSubmit={(e) => handleSubmit(e)}>{headerForm}</Form>
      )}
    </div>
  );
}
