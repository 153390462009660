import React from "react";
import { Container, Image, Row, Col, Form } from "react-bootstrap";
import { voted } from "../../lib/images";
import "./SendEmail.styles.scss";

function SendEmail() {
  return (
    <div className="ctn-send-email">
      <Container>
        <Row>
          <Col xs="12" lg="6" className="col-left my-auto">
            <Image src={voted} alt="" />
          </Col>
          <Col xs="12" lg="6" className="col-right">
            <div className="div-background">
              <div className="div-below">
                <div className="div-above">
                  <div className="step">
                    <div className="step-number">3)</div>
                    <div className="stepInfo">
                      <div className="title">
                        Stay updated on the latest Georgia voting info.
                      </div>
                      <Form
                        className="action_form"
                        name="act"
                        method="POST"
                        action="https://act.progressga.org/act/"
                        acceptCharset="utf-8"
                        inline
                      >
                        <div className="ak-grid-row">
                          <div
                            id="signup-story"
                            className="ak-grid-col ak-grid-col-6-of-12"
                          >
                            <div className="ak-mobile-lead"></div>
                          </div>

                          <div
                            id="signup-form"
                            className="ak-grid-col ak-grid-col-6-of-12 ak-styled-fields ak-labels-overlaid ak-errs-below"
                          >
                            <ul className="compact" id="ak-errors"></ul>
                            <div id="unknown_user" className="ak-user-form">
                              <Form.Group
                                id="ak-fieldbox-email"
                                className="required"
                                controlId="formBasicEmail"
                              >
                                <Form.Label for="id_email" srOnly>
                                  Email address
                                  <span className="ak-required-flag">*: </span>
                                </Form.Label>
                                <Form.Control
                                  type="email"
                                  name="email"
                                  id="id_email"
                                  className="ak-userfield-input"
                                  placeholder="Email* "
                                  required
                                />
                              </Form.Group>
                              <input
                                type="hidden"
                                name="country"
                                value="United States"
                              />
                            </div>

                            <div className="ak-privacy ak-errs-below"></div>
                            <button
                              type="submit"
                              className="full btn-send-email"
                            >
                              Sign me up {">"}
                            </button>
                          </div>
                        </div>

                        <input
                          type="hidden"
                          name="page"
                          value="20220510_splashpagesignup"
                        />
                      </Form>
                    </div>
                  </div>
                  <div className="triangle"></div>
                  <div className="dog-ear-above"></div>
                </div>
                <div className="dog-ear-below"></div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default SendEmail;
