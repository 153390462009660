import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";

i18next
.use(initReactI18next)
.use(HttpApi) // Registering the back-end localization
.init({    
	lng: "en",
    fallbackLng: "en",
	interpolation: {
		escapeValue: false,
	},
	debug: false,
});
export default i18next;
