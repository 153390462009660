import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import WebFont from 'webfontloader';
import './lib/lang';

WebFont.load({
  typekit: {
    id: 'ocx3eds'
  },
});

ReactDOM.render(
	<React.StrictMode>
		<React.Suspense
			fallback={
				<div className='d-flex justify-content-center align-items-center vh-100'>
					Loading...
				</div>
			}>
			<App />
		</React.Suspense>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
