import React from "react";
import config from "../lib/config";
import { Helmet } from "react-helmet-async";
import { Container } from "react-bootstrap";
import { Header, Footer, AlertSignup } from "../components";
import "./views.styles.scss";
import { useTranslation } from "react-i18next";

function PrivacyPage() {
  let imageUrl = [
    window.location.protocol,
    "//",
    window.location.host,
    config.seo.privacy.image,
  ].join("");
  let ogUrl = window.location.origin;
  const { t } = useTranslation();
  return (
    <div>
      <Helmet>
        <title>{t("seo_privacy_page_title")}</title>
        <meta name="description" content={t("seo_privacy_page_description")} />
        <meta name="image" content={imageUrl} />
        <meta name="twitter:title" content={t("seo_privacy_tw_title")} />
        <meta
          name="twitter:description"
          content={t("seo_privacy_tw_description")}
        />
        <meta name="twitter:image" content={imageUrl} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta itemProp="name" content={t("seo_privacy_page_title")} />
        <meta
          itemProp="description"
          content={t("seo_privacy_tw_description")}
        />
        <meta itemProp="image" content={imageUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={ogUrl} />
        <meta property="og:title" content={t("seo_privacy_page_title")} />
        <meta
          property="og:description"
          content={t("seo_privacy_tw_description")}
        />
        <meta property="og:image" content={imageUrl} />
      </Helmet>
      <div className="home-top-email">
        <AlertSignup />
      </div>
      <Header />
      <div id="page-wrap">
        <Container>
          <h2>Privacy Policy</h2>
          <h4>Last updated: September 15, 2022</h4>
          <h5>I. Scope</h5>
          <p>
            The Georgia Voter Guide, owned by Progress Georgia, takes your
            privacy seriously. This Privacy Policy describes how we treat the
            information we collect when you visit our website (“The Georgia
            Voter Guide”) at thegeorgiavoterguide.com, and engage with The
            Georgia Voter Guide’s content. If you have any questions about this
            policy or our privacy practices, you can contact us at the email
            provided at the end of this Privacy Policy.{" "}
          </p>
          <h5>II. Personal Information</h5>
          <p>
            The Georgia Voter Guide will never sell or rent personal
            information, including your email address, with any third parties
            for marketing purposes without your express permission. The Georgia
            Voter Guide may share your personal information for the purpose of
            delivering our email to you or as required by law. If you have
            arrived at The Georgia Voter Guide through a referral link, we may
            also share personal information with organizations with similar
            viewpoints and objectives. Referral Link means the link to our
            website containing a unique identification string, which is to be
            used by the referring organization to acquire email subscribers.
          </p>
          <h5>Usage Information & Tracking</h5>
          <p>
            The Georgia Voter Guide may also collect non-personal, aggregated
            information about use of our site through cookies, and products like
            “Google Analytics”. Cookies are text files with small pieces of data
            — like a username and password — that are used to identify your
            computer as you use a computer network. This information is not
            personally identifiable and will only be used to find out how people
            use our site. For example, this information will tell us how much
            time users spend on our site, from which other sites visitors came,
            and to what other sites visitors go. The collection of this
            information allows us to, among other things, prepare for traffic
            load demands. For more information about Google Analytics, please
            visit{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://policies.google.com/technologies/partner-sites"
            >
              www.google.com/policies/privacy/partners/
            </a>
            .
          </p>
          <h5>Age</h5>
          <p>
            The Georgia Voter Guide is not intended for users under the age of
            13. By using The Georgia Voter Guide you represent that you are at
            least 13 years old.
          </p>
          <h5>Review and Access</h5>
          <p>
            Upon your request, we will provide you with a summary of the
            information we collect about you. You will have an opportunity to
            correct, update or modify this information.
          </p>
          <h5>Choice/Opt-Out</h5>
          <p>
            If we ever send you information by email that you did not expressly
            request, we will provide you with an option by which you may request
            no future notices. You can prevent the use of certain cookies by
            modifying your Internet browser settings, typically under the
            sections “Help,” “Internet Options,” or “Settings.” If you disable
            or delete certain cookies in your Internet browser settings, you may
            still access The Georgia Voter Guide, however, you might not be able
            to access or use important functions or features of The Georgia
            Voter Guide.
          </p>
          <h5>Links to Other Sites</h5>
          <p>
            As part of the service, The Georgia Voter Guide may create links
            allowing you to access third party sites. The Georgia Voter Guide is
            not responsible for the content that appears on those sites and does
            not endorse these sites. Please consult those sites’ individual
            privacy policies in order to determine how they treat user
            information.
          </p>
          <h5>Security</h5>
          <p>
            All information described above is stored on restricted database
            servers and we do our best to protect any information collected
            through The Georgia Voter Guide. However, as always the transmission
            of information over the internet is never completely secure.
          </p>
          <h5>Modification to this Policy</h5>
          <p>
            The Georgia Voter Guide may change this policy, but we’ll post any
            changes here, so be sure to check back for future information.
          </p>
          <h5>Contact Information</h5>
          <p>
            You may contact The Georgia Voter Guide by sending an email to{" "}
            <a href="mailto:info@thegeorgiavoterguide.com">
              info@thegeorgiavoterguide.com
            </a>
          </p>
          <h5>Terms and Conditions</h5>
          <p>
            If you use The Georgia Voter Guide, you are agreeing to the above
            terms and conditions. If you do not agree to the above terms and
            conditions, you may not access or use The Georgia Voter Guide.
          </p>
        </Container>
        <Footer flat={true} />
      </div>
    </div>
  );
}

export default PrivacyPage;
