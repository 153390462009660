import React, { useState, useEffect } from "react";
import { Container, Image, Row, Col, Form } from "react-bootstrap";
import "./EmailSignup.styles.scss";
import { closeIconLight, iconWarning } from "../../lib/images";
import * as qs from "qs";
import config from "../../lib/config";
import { useLocation } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

function AlertSignup() {
  const [showAlert, setShowAlert] = useState(false);
  const [status, setStatus] = useState("INITIAL");
  const [email, setEmail] = useState("");
  const [sourceParam, setSourceParam] = useState(
    sessionStorage.getItem(config.akSourceName),
  );

  const { t } = useTranslation();
  const location = useLocation();
  const country = "United States";
  useEffect(() => {
    if (!sourceParam)
      setSourceParam(
        qs.parse(location.search, { ignoreQueryPrefix: true })[
          config.akSourceName
        ],
      ); // If no stored source exists, check QS. Use if found.
  }, [location, sourceParam]);

  const toggleAlert = () => {
    setShowAlert(false);
  };
  function handleSubmit(e) {
    e.preventDefault();
    var formData = new URLSearchParams();
    let pageForm = "gavgalertbanner";
    formData.append("page", pageForm);
    formData.append("email", email);
    if (sourceParam) {
      formData.append("source", sourceParam);
    }
    formData.append("country", country);

    window.session.postSubscribeEmail(formData).then(
      function (res) {
        setStatus("SUCCESS");
      },
      function (e) {
        setStatus("FAILED");
      },
    );
  }

  const renderForm = (
    <Container fluid>
      <Row noGutters>
        <Col className="email-signup-input close-padding">
          <div className="action_form">
            <div className="ak-grid-row">
              <Container>
                <Row noGutters className="form-email">
                  <Col className="alert-email-title">
                    <div className="alert-responsive">
                      <div>{t("information_you_need")}</div>
                      <div>
                        <a
                          href="/check-vote"
                          target="_self"
                          rel="noreferrer"
                        >
                          <button type="button" className="full btn-send-email">
                            {t("confirm_your_ballot")}
                          </button>
                        </a>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </Col>
        <div className={"close-email-signup"}>
          <Image
            src={closeIconLight}
            className="btn-close-signup clickable"
            alt=""
            onClick={() => toggleAlert()}
          />
        </div>
      </Row>
    </Container>
  );
  const renderStatus = (status) => {
    switch (status) {
      case "INITIAL":
        return renderForm;
      case "SUCCESS":
        return (
          <span className="alert-email-title">
            {t("thanks_check_your_email")}
          </span>
        );
      case "FAILED":
        return (
          <span className="alert-email-title">
            There has been an issue, please try again.
          </span>
        );
      default:
        return renderForm;
    }
  };
  if (showAlert) {
    return (
      <div className="ctn-email-signup">
        {renderStatus(status)}
        {/* <div className='email-signup-input'>
					<Container className='alert-email-title'>
						<span className='warning-title'>
							<Image src={iconWarning} className='icon-warning' />{" "}
							{t("important")}:
						</span>{" "}
						<span>
						<Trans i18nKey='runoff_alert'>
							<a href={t("tel_link")}> </a>
						</Trans>
						</span>
					</Container>
					<div className={"close-email-signup"}>
						<Image
							src={closeIconLight}
							className='btn-close-signup'
							alt=''
							onClick={() => toggleAlert()}
						/>
					</div>
				</div> */}
      </div>
    );
  }
  return "";
}
export default AlertSignup;
