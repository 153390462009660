import React from "react";
import { Image, Container, Row, Col } from "react-bootstrap";
import "./voteWithRecord.style.scss";
import {
  starIconOrange,
  iconPlus,
  iconMinus,
  iconHandOrange,
  iconQuizLP04,
  resourceImage1,
  resourceImage2,
  resourceImage3,
} from "../../lib/images";
import Collapse from "react-bootstrap/Collapse";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

function QuizLPVoteWithRecord() {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const { t } = useTranslation();
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  const [sec1, setSec1] = useState(!isMobile);
  const [sec2, setSec2] = useState(false);
  const [sec3, setSec3] = useState(false);
  const [sec4, setSec4] = useState(false);
  const [sec5, setSec5] = useState(false);
  const [sec6, setSec6] = useState(false);
  const [sec7, setSec7] = useState(false);

  const toggleSec = (sec) => {
    setSec1(false);
    setSec2(false);
    setSec3(false);
    setSec4(false);
    setSec5(false);
    setSec6(false);
    setSec7(false);
    if (sec === "sec1") setSec1(true);
    if (sec === "sec2") setSec2(true);
    if (sec === "sec3") setSec3(true);
    if (sec === "sec4") setSec4(true);
    if (sec === "sec5") setSec5(true);
    if (sec === "sec6") setSec6(true);
    if (sec === "sec7") setSec7(true);
  };
  function goToAnchor(anchor) {
    var loc = document.location.toString().split("#")[0];
    document.location = loc + "#" + anchor;
    return false;
  }
  let desktopFAQs = (
    <div id="collapse-text-05" className="collapse-text-content">
      <div className="collape-item-leader">
        <Trans i18nKey="we_have_compiled">
          <HashLink
            to="/voting-with-a-record#resources"
            onClick={() => setOpen3(true)}
          >
            {" "}
          </HashLink>
        </Trans>
      </div>
      <div className="collapse-text-item">
        <div className="col-left">
          <div className="text-content">
            <button
              type="button"
              className={sec1 ? "btn-action-what active" : "btn-action-what"}
              onClick={() => toggleSec("sec1")}
            >
              {t("can_i_vote_1")}
            </button>
            <button
              type="button"
              className={sec2 ? "btn-action-what active" : "btn-action-what"}
              onClick={() => toggleSec("sec2")}
            >
              {t("can_i_vote_2")}
            </button>
            <button
              type="button"
              className={sec3 ? "btn-action-what active" : "btn-action-what"}
              onClick={() => toggleSec("sec3")}
            >
              {t("can_i_vote_3")}
            </button>
            <button
              type="button"
              className={sec4 ? "btn-action-what active" : "btn-action-what"}
              onClick={() => toggleSec("sec4")}
            >
              {t("can_i_vote_4")}
            </button>
            <button
              type="button"
              className={sec5 ? "btn-action-what active" : "btn-action-what"}
              onClick={() => toggleSec("sec5")}
            >
              {t("can_i_vote_5")}
            </button>
          </div>
        </div>
        <div className="col-right">
          {sec1 ? (
            <div className="section-item">
              <div className="col-right-text">
                <div className="title">{t("can_i_vote_1_tab1")}</div>
                <div className="content">
                  <Trans i18nKey="can_i_vote_1_tab2">
                    <a href="tel:8889101368"> </a>
                  </Trans>
                </div>
              </div>
            </div>
          ) : null}
          {sec2 ? (
            <div className="section-item">
              <div className="col-right-text">
                <div className="title">{t("can_i_vote_2_tab1")}</div>
                <div className="content">
                  <p>{t("can_i_vote_2_tab2")}</p>
                  <p>
                    <span className="bg-emphasis">
                      {t("can_i_vote_2_tab3")}
                    </span>
                  </p>
                  <p>
                    <Trans i18nKey="can_i_vote_2_tab4">
                      <span className="bg-emphasis"> </span>
                    </Trans>
                  </p>
                  <p>
                    <Trans i18nKey="can_i_vote_2_tab5">
                      <span className="bg-emphasis"> </span>
                    </Trans>
                  </p>
                  <p>
                    <Trans i18nKey="can_i_vote_2_tab6">
                      <Link to="/register-to-vote"> </Link>
                      <a
                        href="https://dcs.georgia.gov/contact-us"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        {" "}
                      </a>
                    </Trans>
                  </p>
                  <p>
                    <em>{t("can_i_vote_2_tab7")}</em>
                  </p>
                </div>
                <div className="content">
                  <div className="text-content-note">
                    <Image src={iconHandOrange} className="icon-hand-orange" />
                    <div className="text-content-note-t">
                      {t("can_i_vote_2_tab8")}
                    </div>
                  </div>
                </div>
                <div className="content">
                  <div className="text-content-note">
                    <Image src={iconHandOrange} className="icon-hand-orange" />
                    <div className="text-content-note-t">
                      {t("can_i_vote_2_tab9")}
                    </div>
                  </div>
                </div>
                <div className="content">
                  <div className="text-content-note">
                    <Image src={iconHandOrange} className="icon-hand-orange" />
                    <div className="text-content-note-t">
                      {t("can_i_vote_2_tab10")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {sec3 ? (
            <div className="section-item">
              <div className="col-right-text">
                <div className="title">{t("can_i_vote_3_tab1")}</div>
                <div className="content">
                  <p>
                    <i>
                      <Trans i18nKey="can_i_vote_3_tab2">
                        <a
                          href="http://GJP.org/probation"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          {" "}
                        </a>
                      </Trans>
                    </i>
                  </p>
                </div>
                <div className="content">{t("can_i_vote_3_tab3")}</div>
              </div>
            </div>
          ) : null}
          {sec4 ? (
            <div className="section-item">
              <div className="col-right-text">
                <div className="title">{t("can_i_vote_4_tab1")}</div>
                <div className="content">
                  <p>{t("can_i_vote_4_tab2")}</p>
                </div>
                <div className="content">
                  <p>
                    <Trans i18nKey="can_i_vote_4_tab3">
                      <span className="bg-emphasis"> </span>
                      <Link to="/vote-by-mail"> </Link>
                    </Trans>
                  </p>
                </div>
                <div className="content">
                  <div className="text-content-note">
                    <Image src={iconHandOrange} className="icon-hand-orange" />
                    <div className="text-content-note-t">
                      <Trans i18nKey="can_i_vote_4_tab4">
                        <a
                          href="https://securemyabsenteeballot.sos.ga.gov/resource/1662695148000/AbsenteeBallotPDF"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          {" "}
                        </a>
                      </Trans>
                    </div>
                  </div>
                </div>
                <div className="content">
                  <div className="text-content-note">
                    <Image src={iconHandOrange} className="icon-hand-orange" />
                    <div className="text-content-note-t">
                      <Trans i18nKey="can_i_vote_4_tab5">
                        <a
                          href=" https://sos.ga.gov/county-elections-offices"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          {" "}
                        </a>
                      </Trans>
                    </div>
                  </div>
                </div>
                <div className="content">
                  <div className="text-content-note">
                    <Image src={iconHandOrange} className="icon-hand-orange" />
                    <div className="text-content-note-t">
                      {t("can_i_vote_4_tab6")}
                    </div>
                  </div>
                </div>
                <div className="content">
                  <Trans i18nKey="can_i_vote_4_tab7">
                    <a
                      href="https://www.splcenter.org/our-issues/voting-rights/voting-rights-ga"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {" "}
                    </a>
                  </Trans>
                </div>
              </div>
            </div>
          ) : null}
          {sec5 ? (
            <div className="section-item">
              <div className="col-right-text">
                <div className="title">{t("can_i_vote_5_tab1")}</div>
                <div className="content">
                  <div className="text-content-note">
                    <Image src={iconHandOrange} className="icon-hand-orange" />
                    <div className="text-content-note-t">
                      {t("can_i_vote_5_tab2")}
                    </div>
                  </div>
                </div>
                <div className="content">
                  <div className="text-content-note">
                    <Image src={iconHandOrange} className="icon-hand-orange" />
                    <div className="text-content-note-t">
                      {t("can_i_vote_5_tab3")}
                    </div>
                  </div>
                </div>
                <div className="content">
                  <div className="text-content-note">
                    <Image src={iconHandOrange} className="icon-hand-orange" />
                    <div className="text-content-note-t">
                      {t("can_i_vote_5_tab4")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
  let mobileFAQs = (
    <div id="collapse-text-05" className="collapse-text-content">
      <div className="collapse-item mobile-collapse">
        <div className="collape-item-leader">
          <Trans i18nKey="we_have_compiled">
            <HashLink
              to="/voting-with-a-record#resources"
              onClick={() => setOpen3(true)}
            >
              {" "}
            </HashLink>
          </Trans>
        </div>
        <div className="collape-item-top">
          <div className="collape-item-lable">{t("can_i_vote_1")}</div>
          <button
            type="button"
            className="collape-item-button"
            onClick={() => setSec1(!sec1)}
            aria-controls="collapse-faq-01"
            aria-expanded={sec1}
          >
            {sec1 ? (
              <Image src={iconMinus} className="icon-minus" />
            ) : (
              <Image src={iconPlus} />
            )}
          </button>
        </div>
        <Collapse in={sec1} className="col-right">
          <Container id="collapse-faq-01" className="section-item">
            <div>
              <div className="title">{t("can_i_vote_1_tab1")}</div>
              <div className="content">
                <Trans i18nKey="can_i_vote_1_tab2">
                  <a href="tel:8889101368"> </a>
                </Trans>
              </div>
            </div>
          </Container>
        </Collapse>
      </div>
      <div className="collapse-item mobile-collapse">
        <div className="collape-item-top">
          <div className="collape-item-lable">{t("can_i_vote_2")}</div>
          <button
            type="button"
            className="collape-item-button"
            onClick={() => setSec2(!sec2)}
            aria-controls="collapse-faq-02"
            aria-expanded={sec2}
          >
            {sec2 ? (
              <Image src={iconMinus} className="icon-minus" />
            ) : (
              <Image src={iconPlus} />
            )}
          </button>
        </div>
        <Collapse in={sec2} className="col-right">
          <Container id="collapse-faq-02" className="section-item">
            <div className="title">{t("can_i_vote_2_tab1")}</div>
            <div className="content">
              <p>{t("can_i_vote_2_tab2")}</p>
              <p>
                <span className="bg-emphasis">{t("can_i_vote_2_tab3")}</span>
              </p>
              <p>
                <Trans i18nKey="can_i_vote_2_tab4">
                  <span className="bg-emphasis"> </span>
                </Trans>
              </p>
              <p>
                <Trans i18nKey="can_i_vote_2_tab5">
                  <span className="bg-emphasis"> </span>
                </Trans>
              </p>
              <p>
                <Trans i18nKey="can_i_vote_2_tab6">
                  <Link to="/register-to-vote"> </Link>
                  <a
                    href="https://dcs.georgia.gov/contact-us"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {" "}
                  </a>
                </Trans>
              </p>
              <p>
                <em>{t("can_i_vote_2_tab7")}</em>
              </p>
            </div>
            <div className="content">
              <div className="text-content-note">
                <Image src={iconHandOrange} className="icon-hand-orange" />
                <div className="text-content-note-t">
                  {t("can_i_vote_2_tab8")}
                </div>
              </div>
            </div>
            <div className="content">
              <div className="text-content-note">
                <Image src={iconHandOrange} className="icon-hand-orange" />
                <div className="text-content-note-t">
                  {t("can_i_vote_2_tab9")}
                </div>
              </div>
            </div>
            <div className="content">
              <div className="text-content-note">
                <Image src={iconHandOrange} className="icon-hand-orange" />
                <div className="text-content-note-t">
                  {t("can_i_vote_2_tab10")}
                </div>
              </div>
            </div>
          </Container>
        </Collapse>
      </div>
      <div className="collapse-item mobile-collapse">
        <div className="collape-item-top">
          <div className="collape-item-lable">{t("can_i_vote_3")}</div>
          <button
            type="button"
            className="collape-item-button"
            onClick={() => setSec3(!sec3)}
            aria-controls="collapse-faq-03"
            aria-expanded={sec3}
          >
            {sec3 ? (
              <Image src={iconMinus} className="icon-minus" />
            ) : (
              <Image src={iconPlus} />
            )}
          </button>
        </div>
        <Collapse in={sec3} className="col-right">
          <Container id="collapse-faq-03" className="section-item">
            <div className="title">{t("can_i_vote_3_tab1")}</div>
            <div className="content">
              <i>
                <Trans i18nKey="can_i_vote_3_tab2">
                  <a
                    href="http://GJP.org/probation"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {" "}
                  </a>
                </Trans>
              </i>
            </div>
            <div className="content">{t("can_i_vote_3_tab3")}</div>
          </Container>
        </Collapse>
      </div>
      <div className="collapse-item mobile-collapse">
        <div className="collape-item-top">
          <div className="collape-item-lable">{t("can_i_vote_4")}</div>
          <button
            type="button"
            className="collape-item-button"
            onClick={() => setSec4(!sec4)}
            aria-controls="collapse-faq-04"
            aria-expanded={sec4}
          >
            {sec4 ? (
              <Image src={iconMinus} className="icon-minus" />
            ) : (
              <Image src={iconPlus} />
            )}
          </button>
        </div>
        <Collapse in={sec4} className="col-right">
          <Container id="collapse-faq-04" className="section-item">
            <div>
              <div className="title">{t("can_i_vote_4_tab1")}</div>
              <div className="content">{t("can_i_vote_4_tab2")}</div>
              <div className="content">
                <Trans i18nKey="can_i_vote_4_tab3">
                  <span className="bg-emphasis"> </span>
                  <Link to="/vote-by-mail"> </Link>
                </Trans>
              </div>
              <div className="content">
                <div className="text-content-note">
                  <Image src={iconHandOrange} className="icon-hand-orange" />
                  <div className="text-content-note-t">
                    <Trans i18nKey="can_i_vote_4_tab4">
                      <a
                        href="https://securemyabsenteeballot.sos.ga.gov/resource/1662695148000/AbsenteeBallotPDF"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        {" "}
                      </a>
                    </Trans>
                  </div>
                </div>
              </div>
              <div className="content">
                <div className="text-content-note">
                  <Image src={iconHandOrange} className="icon-hand-orange" />
                  <div className="text-content-note-t">
                    <Trans i18nKey="can_i_vote_4_tab5">
                      <a
                        href=" https://sos.ga.gov/county-elections-offices"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        {" "}
                      </a>
                    </Trans>
                  </div>
                </div>
              </div>
              <div className="content">
                <div className="text-content-note">
                  <Image src={iconHandOrange} className="icon-hand-orange" />
                  <div className="text-content-note-t">
                    {t("can_i_vote_4_tab6")}
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </Collapse>
      </div>
      <div className="collapse-item mobile-collapse">
        <div className="collape-item-top">
          <div className="collape-item-lable">{t("you_end_up")}</div>
          <button
            type="button"
            className="collape-item-button"
            onClick={() => setSec5(!sec5)}
            aria-controls="collapse-faq-05"
            aria-expanded={sec5}
          >
            {sec5 ? (
              <Image src={iconMinus} className="icon-minus" />
            ) : (
              <Image src={iconPlus} />
            )}
          </button>
        </div>
        <Collapse in={sec5} className="col-right">
          <Container id="collapse-faq-05" className="section-item">
            <div>
              <div className="title">{t("can_i_vote_5_tab1")}</div>
              <div className="content">
                <div className="text-content-note">
                  <Image src={iconHandOrange} className="icon-hand-orange" />
                  <div className="text-content-note-t">
                    {t("can_i_vote_5_tab2")}
                  </div>
                </div>
              </div>
              <div className="content">
                <div className="text-content-note">
                  <Image src={iconHandOrange} className="icon-hand-orange" />
                  <div className="text-content-note-t">
                    {t("can_i_vote_5_tab3")}
                  </div>
                </div>
              </div>
              <div className="content">
                <div className="text-content-note">
                  <Image src={iconHandOrange} className="icon-hand-orange" />
                  <div className="text-content-note-t">
                    {t("can_i_vote_5_tab4")}
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </Collapse>
      </div>
    </div>
  );
  return (
    <div className="ctn-quiz-lp04 vote-with-a-record">
      <div className="quiz-lp-top-header">
        <Image src={starIconOrange} className="icon-star-left" alt="" />
        <div className="top-header-title">
          <p>{t("if_you_have_record")}</p>
        </div>
        <Image src={starIconOrange} className="icon-star-right" alt="" />
      </div>
      <div className="quiz-lp-btn-action">
        <button
          type="button"
          className="btn-action-item"
          onClick={() => {
            setOpen1(true);
            goToAnchor("eligible");
            return false;
          }}
        >
          {t("am_i_eligible")}
        </button>
        <button
          type="button"
          className="btn-action-item"
          onClick={() => {
            setOpen2(true);
            goToAnchor("faq");
            return false;
          }}
        >
          {t("FAQ")}
        </button>
        <button
          type="button"
          className="btn-action-item"
          onClick={() => {
            setOpen3(true);
            goToAnchor("resources");
            return false;
          }}
        >
          {t("other_resources")}
        </button>
      </div>
      <div className="collape-item" id="eligible">
        <div className="collape-item-top">
          <div className="collape-item-lable">{t("voting_eligibility")}</div>
          <button
            type="button"
            className="collape-item-button"
            onClick={() => setOpen1(!open1)}
            aria-controls="collapse-text-04"
            aria-expanded={open1}
          >
            {open1 ? (
              <Image src={iconMinus} className="icon-minus" />
            ) : (
              <Image src={iconPlus} />
            )}
          </button>
        </div>
        <Collapse in={open1}>
          <div id="collapse-text-04" className="collapse-text-content">
            <div className="collapse-text-item">
              <div className="col-left">
                <div className="text-content">
                  {t("according_to_the_georgia")}
                </div>
                <div className="text-content-note">
                  <Image src={iconHandOrange} className="icon-hand-orange" />
                  <div className="text-content-note-t">
                    {t("you_are_not_currently")}
                  </div>
                </div>
                <div className="text-content-note">
                  <Image src={iconHandOrange} className="icon-hand-orange" />
                  <div className="text-content-note-t">{t("you_are_off")}</div>
                </div>
                <div className="text-content-note">
                  <Image src={iconHandOrange} className="icon-hand-orange" />
                  <div className="text-content-note-t">
                    {t("you_are_serving")}
                  </div>
                </div>
                <div className="text-content-note">
                  <Image src={iconHandOrange} className="icon-hand-orange" />
                  <div className="text-content-note-t">
                    {t("your_sentence_is_complete")}
                  </div>
                </div>
                <div className="text-content-note">
                  <Image src={iconHandOrange} className="icon-hand-orange" />
                  <div className="text-content-note-t">
                    {t("you_meet_requirement")}
                  </div>
                </div>
              </div>
              <div className="col-right">
                <Image src={iconQuizLP04} />
              </div>
            </div>
          </div>
        </Collapse>
      </div>
      <div className="collape-item" id="faq">
        <div className="collape-item-top">
          <div className="collape-item-lable">{t("FAQ_about_voting")}</div>
          <button
            type="button"
            className="collape-item-button"
            onClick={() => setOpen2(!open2)}
            aria-controls="collapse-text-03"
            aria-expanded={open2}
          >
            {open2 ? (
              <Image src={iconMinus} className="icon-minus" />
            ) : (
              <Image src={iconPlus} />
            )}
          </button>
        </div>
        <Collapse in={open2}>{isMobile ? mobileFAQs : desktopFAQs}</Collapse>
      </div>

      <div className="collape-item" id="resources">
        <div className="collape-item-top">
          <div className="collape-item-lable">{t("other_resources")}</div>
          <button
            type="button"
            className="collape-item-button"
            onClick={() => setOpen3(!open3)}
            aria-controls="collapse-text-01"
            aria-expanded={open3}
          >
            {open3 ? (
              <Image src={iconMinus} className="icon-minus" />
            ) : (
              <Image src={iconPlus} />
            )}
          </button>
        </div>
        <Collapse in={open3}>
          <Container
            id="collapse-text-01"
            className="collapse-text-content"
            fluid
          >
            <Row>
              <Col xs="12" md="4">
                <p className="content">{t("resources_text")}</p>
              </Col>
              <Col xs="12" md="3">
                <a
                  className="resource-button"
                  href="https://gjp.org/voting/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("visit_button_1")}
                </a>
                <a
                  className="resource-button"
                  href="https://www.splcenter.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("visit_button_2")}
                </a>
                <a
                  className="resource-button "
                  href="https://www.schr.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("visit_button_3")}
                </a>
              </Col>
              <Col xs="12" md="3">
                <Image
                  src={resourceImage1}
                  className="resource-image"
                  alt="Resource Image"
                />
                <Image
                  src={resourceImage2}
                  className="resource-image"
                  alt="Resource Image"
                />
              </Col>
              <Col xs={{ span: 4, offset: 4 }} md={{ span: 2, offset: 0 }}>
                <Image
                  src={resourceImage3}
                  className="resource-image"
                  alt="Resource Image"
                />
              </Col>
            </Row>
          </Container>
          {/* <div id="collapse-text-01" className="collapse-text-content">
            <div className="collapse-text-item">
              <div className="col-left">
                <div className="text-content">{t("qvip2_early_voting_starts")}</div>
              </div>
              <div className="col-right">
                <Image src={iconQuizLP01} />
              </div>

            </div>
          </div> */}
        </Collapse>
      </div>
    </div>
  );
}
export default QuizLPVoteWithRecord;
