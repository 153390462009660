import React from "react";
import { Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faTwitterSquare,
} from "@fortawesome/free-brands-svg-icons";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { faSquare } from "@fortawesome/free-solid-svg-icons";
import { badgeFactLight, badgeFactDark } from "../../lib/images";
import "./Quiz.style.scss";
import { useTranslation } from "react-i18next";
function QuizCard({ item, toggleShowFact, toggleShowMyth, selected }) {
  let origin = window.location.origin;
  let fbShare = origin + "/learn-more";
  const { t } = useTranslation();
  return (
    <div className="quiz-item">
      {item.isShowFact === false ? (
        ""
      ) : (
        <Image
          src={badgeFactDark}
          alt=""
          className="quiz-badge quiz-badge-dark-fact"
        />
      )}
      {item.isShowMyth === false ? (
        ""
      ) : (
        <Image
          src={badgeFactLight}
          alt=""
          className="quiz-badge quiz-badge-light-fact"
        />
      )}

      <Image src={item.imgUrl} alt={item.title} className="quiz-item-img" />
      <div className="quiz-item-title"> {t(item.title)}</div>
      <div className="quiz-item-action">
        <button
          type="button"
          disabled={item.isAnswered}
          className={item.isShowFact ? "btn-action btn-active" : "btn-action"}
          onClick={() => toggleShowFact()}
        >
          {t("fact")}
        </button>
        <button
          type="button"
          disabled={item.isAnswered}
          className={
            item.isShowMyth
              ? "btn-action btn-active btn-myth"
              : "btn-action btn-myth"
          }
          onClick={() => toggleShowMyth()}
        >
          {t("myth")}
        </button>
      </div>
      <div className="quiz-answer">
        {selected ? (
          <div className="show-quiz">
            {" "}
            {item.isShowFact
              ? t(item.fact)
              : item.isShowMyth
              ? t(item.myth)
              : ""}{" "}
          </div>
        ) : null}
      </div>
      {selected && (item.isShowFact === true || item.isShowMyth === true) ? (
        <div className="quiz-social">
          <FacebookShareButton url={fbShare}>
            <span className="fa-layers fa-fw">
              <FontAwesomeIcon icon={faSquare} size="md" color="white" />
              <FontAwesomeIcon
                icon={faFacebookSquare}
                size="md"
                color="#193946"
              />
            </span>
          </FacebookShareButton>
          <TwitterShareButton url={item.twShare} title={item.twDes}>
            <span className="fa-layers fa-fw">
              <FontAwesomeIcon icon={faSquare} size="md" color="white" />
              <FontAwesomeIcon
                icon={faTwitterSquare}
                size="md"
                color="#193946"
              />
            </span>
          </TwitterShareButton>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
export default QuizCard;
