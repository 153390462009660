import axios from "axios";
import config from "./config";

export default class Session {
	constructor() {
		// let env = process.env.REACT_APP_ENV;
		this.apiBase = config.apis.baseDev;
		// switch (env) {
		// 	case "development":
		// 		this.apiBase = config.apis.baseDev;
		// 		break;
		// 	case "staging":
		// 		this.apiBase = config.apis.base;
		// 		break;
		// 	case "production":
		// 		this.apiBase = config.apis.base;
		// 		break;
		// 	default:
		// 		break;
		// }
		this.getAddressLookup = this.getAddressLookup.bind(this);
		this.getHomeData = this.getHomeData.bind(this);
	}

	getAddressLookup(address) {
		let url = config.apis.ballotAPI;
		url = url + address + "&search_type=address&state=GA";
		return axios
			.get(url)
			.then((response) => {
				let matchData = response.data;
				if (matchData) {
					let municipality = matchData["municipal"] || "";
					municipality = municipality.replace(/\s+/g, "_");

					let ballot = {
						cd: matchData["cd"] || "",
						sldl: matchData["sldl"] || "",
						sldu: matchData["sldu"] || "",
						county: matchData["county"] || "",
						countyDistrict: matchData["countyDistrict"] || "",
						municipality: municipality,
						schoolDistrict: matchData["schoolDistrict"] || "",
					};

					return ballot;
				} else {
					return {
						lookupError: "No address found, please try again!",
					};
				}
			})
			.catch((error) => {
				// let status = error.response && error.response.status;
				// if (status === 500) {
				// 	window.history.pushState("", "", "/500");
				// }
				let response =
					error.response &&
					error.response.data &&
					error.response.data.lookupError
				return {
					lookupError: response,
				};
			});
	}

	getHomeData() {
		let url = this.apiBase + config.apis.races + "?is_statewide=true";
		return axios
			.get(url)
			.then((response) => {
				let data = response && response.data && response.data;
				return data;
			})
			.catch((error) => {
				let status = error.response && error.response.status;
				if (status === 500) {
					window.history.pushState("", "", "/500");
				}
				let response =
					error.response &&
					error.response.data &&
					error.response.data.error &&
					error.response.data.error.message;
				return {
					lookupError: response || "Error",
				};
			});
	}

	getBallotData(payload, lang) {
		let raceAPI;
		switch (lang) {
			case "":
				raceAPI = config.apis.races;
				break;
			case "kr":
				raceAPI = config.apis.races.slice(0, -1) + "_ko/";
				break;
			case "es":
				raceAPI = config.apis.races.slice(0, -1) + "_es/";
				break;
			default:
				raceAPI = config.apis.races;
				break;
		}
		let query = config.apis.ballotFilters
			.replace("{sldl}", payload.sldl || "")
			.replace("{sldu}", payload.sldu || "")
			.replace("{cd}", payload.cd || "")
			.replace("{county}", payload.county || "");
		let url = this.apiBase + raceAPI + "?filters=" + escape(query);
		return axios
			.get(url)
			.then((response) => {
				let data = response && response.data;
				return data;
			})
			.catch((error) => {
				let status = error.response && error.response.status;
				if (status === 500) {
					window.history.pushState("", "", "/500");
				}
				let response =
					error.response &&
					error.response.data &&
					error.response.data.error &&
					error.response.data.error.message;
				return {
					lookupError: response || "Error",
				};
			});
	}

	getRaceData(payload, lang) {
		let raceAPI;
		switch (lang) {
			case "":
				raceAPI = config.apis.races;
				break;
			case "kr":
				raceAPI = config.apis.races.slice(0, -1) + "_ko/";
				break;
			case "es":
				raceAPI = config.apis.races.slice(0, -1) + "_es/";
				break;
			default:
				raceAPI = config.apis.races;
				break;
		}
		let query;
		if (payload.geography_id) {
			query = `(geography_id=${payload.geography_id})`;
		} else if (payload.race_type) {
			query = `(race_type=${payload.race_type})`;
		}
		// let query = config.apis.raceFilters.replace("{race}", payload.race);
		let url = this.apiBase + raceAPI + "?filters=" + escape(query);
		return axios
			.get(url)
			.then((response) => {
				let data = response && response.data;
				return data;
			})
			.catch((error) => {
				let status = error.response && error.response.status;
				if (status === 500) {
					window.history.pushState("", "", "/500");
				}
				let response =
					error.response &&
					error.response.data &&
					error.response.data.error &&
					error.response.data.error.message;
				return {
					lookupError: response || "Error",
				};
			});
	}

	getAmendments(lang) {
		let endpoint;
		switch (lang) {
			case "":
				endpoint = config.apis.amendments;
				break;
			case "kr":
				endpoint = config.apis.amendments.slice(0, -1) + "_ko/";
				break;
			case "es":
				endpoint = config.apis.amendments.slice(0, -1) + "_es/";
				break;
			default:
				endpoint = config.apis.amendments;
				break;
		}
		let url = this.apiBase + endpoint;
		return axios
			.get(url)
			.then((response) => {
				let data = response && response.data && response.data;
				return data;
			})
			.catch((error) => {
				let status = error.response && error.response.status;
				if (status === 500) {
					window.history.pushState("", "", "/500");
				}
				let response =
					error.response &&
					error.response.data &&
					error.response.data.error &&
					error.response.data.error.message;
				return {
					lookupError: response || "Error",
				};
			});
	}

	postSubscribeEmail(payload) {
		let url = config.apis.actionKit;
		let configs = {
			method: "POST",
			mode: "no-cors",
			headers: {
				"Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
				Accept: "application/json",
			},
			body: payload,
		};
		return fetch(url, configs);
	}
}
