import React from "react";
import { Header, Footer } from "../components";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import "./views.styles.scss";

function Error404() {
  return (
    <div className="page-not-found">
      <Header />
      <div id="page-wrap">
        <Container style={{ margin: "100px auto", textAlign: "center" }}>
          <Row>
            <Col>
              {/* <img src={error404} alt="error-404" className="errorImage"/> */}
              <Container>
                <div className="title">Page not found</div>
                <div className="leader">
                  You have found a page that doesn’t exist!{" "}
                </div>
                <Button className="btn-redirect" variant="danger" type="submit">
                  <Link to="/">Go Back</Link>
                </Button>
                <Button className="btn-redirect" variant="danger" type="submit">
                  <Link to="/">Return to home Page</Link>
                </Button>
              </Container>
            </Col>
          </Row>
        </Container>
        {/* <Container className="email-bg-container">
                <EmailCollection />
            </Container> */}
        <Footer />
      </div>
    </div>
  );
}

export default Error404;
