import React, { useEffect } from "react";
import { Image, Collapse, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./QuizLandingPage.style.scss";
import {
  starIconOrange,
  iconPlus,
  iconMinus,
  iconWarning,
  iconHandOrange,
} from "../../lib/images";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

function QuizLPVoteByMail() {
  let hashParam =
    window.location.hash && window.location.hash.replace("#", "").toLowerCase();

  const { t, i18n } = useTranslation();
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);

  useEffect(() => {
    if (hashParam === "cobb-voter") {
      setOpen4("true");
      goToAnchor("cobb-voter");
    }
  }, [hashParam]);

  function goToAnchor(anchor) {
    var loc = document.location.toString().split("#")[0];
    document.location = loc + "#" + anchor;
    return false;
  }

  return (
    <div className="ctn-quiz-lp03">
      {/* <div className="quiz-lp-top-header">
        <Image src={starIconOrange} className="icon-star-left" alt="" />
        <div className="top-header-title">
          <p>
            <Trans i18nKey="qvbm-subhead">
              <span className="bg-emphasis"></span>
            </Trans>
          </p>
        </div>
        <Image src={starIconOrange} className="icon-star-right" alt="" />
      </div> */}
      <div className="quiz-lp-top-content">
        <div className="title">{t("qvbm-content_title")}</div>
        <div className="leader">{t("qvbm-content_leader")}</div>
      </div>
      <div className="quiz-lp-btn-action">
        <button
          type="button"
          className="btn-action-item"
          onClick={() => {
            setOpen1("true");
            goToAnchor("step-01");
            return false;
          }}
        >
          {t("qvbm-action_btn_1")}
        </button>
        <button
          type="button"
          className="btn-action-item"
          onClick={() => {
            setOpen2("true");
            goToAnchor("step-02");
            return false;
          }}
        >
          {t("qvbm-action_btn_2")}
        </button>
        <button
          type="button"
          className="btn-action-item"
          onClick={() => {
            setOpen3("true");
            goToAnchor("step-03");
            return false;
          }}
        >
          {t("qvbm-action_btn_3")}
        </button>
        {/* <button
          type="button"
          className="btn-action-item"
          onClick={() => {
            setOpen4("true");
            goToAnchor("cobb-voter");
            return false;
          }}
        >
          {t("qvbm-action_btn_4")}
        </button> */}
      </div>
      <div className="collape-item">
        <div className="collape-item-top" id="step-01">
          <div className="collape-item-lable">{t("qvbm-step_01_title")}</div>
          <button
            type="button"
            className="collape-item-button"
            onClick={() => setOpen1(!open1)}
            aria-controls="collapse-text-01"
            aria-expanded={open1}
          >
            {open1 ? (
              <Image src={iconMinus} className="icon-minus" />
            ) : (
              <Image src={iconPlus} />
            )}
          </button>
        </div>
        <Collapse in={open1}>
          <div id="collapse-text-01" className="collapse-text-content">
            <div className="collapse-text-item">
              <div className="col-left">
                <div className="text-title">
                  <span className="underline-emphasis">
                    {t("qvbm-step_01_when")}
                  </span>
                </div>
                <div className="text-content">
                  <Trans i18nKey="qvbm-step_01_when_body">
                    <strong></strong>
                  </Trans>
                </div>
              </div>
              <div className="col-right"></div>
            </div>
            <div className="collapse-text-item">
              <div className="col-left">
                <div className="text-title">
                  <span className="underline-emphasis">
                    {t("qvbm-step_01_where")}
                  </span>
                </div>
                <div className="text-content">
                  {t("qvbm-step_01_where_body")}
                </div>
              </div>
              <div className="col-right"></div>
            </div>
            <div className="collapse-text-item">
              <div className="col-left">
                <div className="text-title">
                  <span className="underline-emphasis">
                    {t("qvbm-step_01_why")}
                  </span>
                </div>
                <div className="text-content">{t("qvbm-step_01_why_body")}</div>
              </div>
              <div className="col-right"></div>
            </div>
            <div className="collapse-text-item">
              <div className="col-left">
                <div className="text-title">
                  <span className="underline-emphasis">
                    {t("qvbm-step_01_how")}
                  </span>
                </div>
                <div className="text-content">
                  <Trans i18nKey="qvbm-step_01_how_body">
                    <a
                      href="https://sos.ga.gov/sites/default/files/forms/Absentee_Ballot_Application_20212.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                    </a>
                    <a
                      href="https://sos.ga.gov/county-elections-offices"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                    </a>
                  </Trans>
                </div>
                <a
                  href="https://sos.ga.gov/county-elections-offices"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button type="button" className="btn-action-quiz-lp">
                    {t("qvbm-step_01_how_btn")}
                  </button>
                </a>
                <div className="div-warning">
                  <Image src={iconWarning} className="icon-warning" />{" "}
                  <div className="warning-title">{t("qvbm-important")}</div>
                  <p>
                    <Trans i18nKey="qvbm-important_copy_1">
                      <strong></strong>
                      <strong></strong>
                    </Trans>
                  </p>
                </div>
              </div>
              <div className="col-right"></div>
            </div>
          </div>
        </Collapse>
      </div>
      <div className="collape-item">
        <div className="collape-item-top" id="step-02">
          <div className="collape-item-lable">{t("qvbm-step_02_title")}</div>
          <button
            type="button"
            className="collape-item-button"
            onClick={() => setOpen2(!open2)}
            aria-controls="collapse-text-02"
            aria-expanded={open2}
          >
            {open2 ? (
              <Image src={iconMinus} className="icon-minus" />
            ) : (
              <Image src={iconPlus} />
            )}
          </button>
        </div>
        <Collapse in={open2}>
          <div id="collapse-text-02" className="collapse-text-content">
            <div className="col-left">
              <div className="text-content">
                <Trans i18nKey="qvbm-step_02_body_01">
                  <span className="bg-emphasis"></span>
                </Trans>
              </div>
              <div className="text-content">{t("qvbm-step_02_body_02")}</div>
              <div className="ctn-text-content-note">
                <div className="text-content-note">
                  <span
                    className={
                      "text-content-note-step language-customization-" +
                      i18n.language
                    }
                  >
                    {t("qvbm-step_02_step_01")}
                  </span>
                  <Image src={iconHandOrange} className="icon-hand-orange" />
                  <div className="text-content-note-t">
                    {t("qvbm-step_02_step_01_body")}
                  </div>
                </div>
                <div className="text-content-note">
                  <span
                    className={
                      "text-content-note-step language-customization-" +
                      i18n.language
                    }
                  >
                    {t("qvbm-step_02_step_02")}
                  </span>
                  <Image src={iconHandOrange} className="icon-hand-orange" />
                  <div className="text-content-note-t">
                    {t("qvbm-step_02_step_02_body")}
                  </div>
                </div>
                <div className="text-content-note">
                  <span
                    className={
                      "text-content-note-step language-customization-" +
                      i18n.language
                    }
                  >
                    {t("qvbm-step_02_step_03")}
                  </span>
                  <Image src={iconHandOrange} className="icon-hand-orange" />
                  <div className="text-content-note-t">
                    {t("qvbm-step_02_step_03_body")}
                  </div>
                </div>
                <div className="text-content-note">
                  <span
                    className={
                      "text-content-note-step language-customization-" +
                      i18n.language
                    }
                  >
                    {t("qvbm-step_02_step_04")}
                  </span>
                  <Image src={iconHandOrange} className="icon-hand-orange" />
                  <div className="text-content-note-t">
                    {t("qvbm-step_02_step_04_body")}
                  </div>
                </div>
                <div className="div-warning">
                  <Image src={iconWarning} className="icon-warning" />{" "}
                  <div className="warning-title">{t("qvbm-important")}</div>
                  <p>
                    <Trans i18nKey="qvbm-important_copy_2">
                      <strong></strong>
                    </Trans>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-right"></div>
          </div>
        </Collapse>
      </div>
      <div className="collape-item">
        <div className="collape-item-top" id="step-03">
          <div className="collape-item-lable">{t("qvbm-step_03_title")}</div>
          <button
            type="button"
            className="collape-item-button"
            onClick={() => setOpen3(!open3)}
            aria-controls="collapse-text-03"
            aria-expanded={open3}
          >
            {open3 ? (
              <Image src={iconMinus} className="icon-minus" />
            ) : (
              <Image src={iconPlus} />
            )}
          </button>
        </div>
        <Collapse in={open3}>
          <Container
            id="collapse-text-03"
            className="collapse-text-content"
            fluid
          >
            <div className="text-content-3">
              <p>
                {" "}
                <Trans i18nKey="qvbm-step_03_body">
                  <a
                    href="https://sos.ga.gov/county-elections-offices"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                  </a>
                </Trans>
              </p>
            </div>
            <Row className="turning-ballot" noGutters>
              <Col xs="12" xl="4" className="turning-ballot-item">
                <div className="turning-ballot-item-title">
                  <span className="underline-emphasis">
                    {t("qvbm-step_03_01")}
                  </span>
                </div>
                <div className="turning-ballot-answer">
                  <div className="turning-ballot-des">
                    {t("qvbm-step_03_01_body")}
                  </div>
                </div>
              </Col>
              <Col xs="12" xl="4" className="turning-ballot-item">
                <div className="turning-ballot-item-title">
                  <span className="underline-emphasis">
                    {t("qvbm-step_03_02")}
                  </span>
                </div>
                <div className="turning-ballot-answer">
                  <div className="turning-ballot-des">
                    <Trans i18nKey="qvbm-step_03_02_body">
                      <a href={t("peachvote")} target="_blank" rel="noreferrer">
                        {" "}
                      </a>
                      <a
                        href="https://mvp.sos.ga.gov/s/county-election-offices"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                      </a>
                    </Trans>
                  </div>
                </div>
                <div className="turning-ballot-action">
                  <a
                    href="https://mvp.sos.ga.gov/s/county-election-offices"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button type="button" className="btn-action-quiz-lp">
                      {t("qvbm-step_03_02_btn")}
                    </button>
                  </a>
                </div>
              </Col>
              <Col xs="12" xl="4" className="turning-ballot-item">
                <div className="turning-ballot-item-title">
                  <span className="underline-emphasis">
                    {t("qvbm-step_03_03")}
                  </span>
                </div>
                <div className="turning-ballot-answer">
                  <div className="turning-ballot-des">
                    {t("qvbm-step_03_03_body")}
                  </div>
                </div>
                <div className="turning-ballot-action">
                  <a
                    href="https://mvp.sos.ga.gov/s/county-election-offices"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button type="button" className="btn-action-quiz-lp">
                      {t("qvbm-step_03_03_btn")}
                    </button>
                  </a>
                </div>
              </Col>
            </Row>
            <Row className="div-warning" noGutters>
              <p dangerouslySetInnerHTML={{ __html: t("qvbm-when") }}></p>
              <Col xs="12" xl="2" className="align-baseline">
                <Image src={iconWarning} className="icon-warning" />{" "}
                <div className="warning-title">{t("qvbm-important")}</div>
              </Col>
              <Col xs="12" xl="10">
                <p>
                  <Trans i18nKey="qvbm-important_copy_3">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://mvp.sos.ga.gov/s/"
                    >
                      {" "}
                    </a>
                  </Trans>
                </p>
              </Col>
              <Col xs="12" xl={{ offset: 5 }}>
                <a
                  href="/check-vote"
                  target="_self"
                  rel="noreferrer"
                >
                  <button type="button" className="btn-action-quiz-lp">
                    {t("qvbm-important_3_btn")}
                  </button>
                </a>
              </Col>
            </Row>
          </Container>
        </Collapse>
      </div>
    </div>
  );
}
export default QuizLPVoteByMail;
