import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { iconWarning, iconHandOrange, iconQuizLP01 } from "../../lib/images";
import { HashLink } from "react-router-hash-link";
import "./Calendar.style.scss";
import { Trans, useTranslation } from "react-i18next";

function Calendar(props) {
  let isHome = props.isHome;
  const { t } = useTranslation();
  return (
    <div>
      <Container id="calendar" className="calendar-section" fluid>
        <Row noGutters>
          <div className={isHome ? "title peach-color" : "title"}>
            {t("save_these_important")}
          </div>
          <div className="leader">
            {/* And be sure to{" "}
							<HashLink to='/#voter-status' smooth>
								<strong>check your voter registration status</strong>
							</HashLink>{" "}
							to confirm you’re all set to cast your vote this election */}
            <p>
              <Trans i18nKey="check_your_voter">
                <HashLink to="/#voter-status"></HashLink>
              </Trans>
            </p>
          </div>
        </Row>
        <Row>
          <Col xs="12" xl="7" className="col-left">
            <Container className="ctn-text-content-note" fluid>
              <Row className="text-content-note" noGutters>
                <Col className="text-content-note-step">
                  <Trans i18nKey="voter_registration_deadline">
                    <span class="language-customization-es"></span>
                  </Trans>
                </Col>
                <Col className="text-content-note-t">
                  <Trans i18nKey="november_4">
                    <span class="language-customization-es"></span>
                  </Trans>
                </Col>
              </Row>
              <Row className="text-content-note" noGutters>
                <Col className="text-content-note-step">
                  <Trans i18nKey="last_day_to_request">
                    <span class="language-customization-es"></span>
                  </Trans>
                </Col>
                <Col className="text-content-note-t">
                  <Trans i18nKey="november_22">
                    <span class="language-customization-es"></span>
                  </Trans>
                </Col>
              </Row>
              <Row className="text-content-note" noGutters>
                <Col className="text-content-note-step">
                  <Trans i18nKey="early_voting_starts">
                    <span class="language-customization-es"></span>
                  </Trans>
                </Col>
                <Col className="text-content-note-t">
                  <Trans i18nKey="november_25">
                    <span class="language-customization-es"></span>
                  </Trans>
                </Col>
              </Row>
              <Row className="text-content-note" noGutters>
                <Col className="text-content-note-step">
                  <Trans i18nKey="early_voting_ends">
                    <span class="language-customization-es"></span>
                  </Trans>
                </Col>
                <Col className="text-content-note-t">
                  <Trans i18nKey="november_27">
                    <span class="language-customization-es"></span>
                  </Trans>
                </Col>
              </Row>
              <Row className="text-content-note" noGutters>
                <Col className="text-content-note-step">
                  <Trans i18nKey="election_day">
                    <span class="language-customization-es"></span>
                  </Trans>
                </Col>
                <Col className="text-content-note-t">
                  <Trans i18nKey="december_3">
                    <span class="language-customization-es"></span>
                  </Trans>
                </Col>
              </Row>
              <Row className="text-content-note" noGutters>
                <Col className="text-content-note-step">
                  <Trans i18nKey="ballot_cure_deadline">
                    <span class="language-customization-es"></span>
                  </Trans>
                </Col>
                <Col className="text-content-note-t">
                  <Trans i18nKey="december_6">
                    <span class="language-customization-es"></span>
                  </Trans>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col xs="12" lg="5" className="col-right d-none d-xl-block">
            <Image src={iconQuizLP01} />
          </Col>
        </Row>
        <Row>
          <Container className="div-warning">
            <Image src={iconWarning} className="icon-warning" />{" "}
            <div className="warning-title">{t("important")}:</div>
            <p>
              <Trans i18nKey="important_home">
                <Link to="/vote-by-mail">Vote by Mail Hub</Link>
              </Trans>
            </p>
          </Container>
        </Row>
      </Container>
    </div>
  );
}
export default Calendar;
