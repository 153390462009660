import React from "react";
import { Header, EmailCollection, Footer } from "../components";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import "./views.styles.scss";

function Error500() {
  return (
    <div>
      <Header />
      <Container style={{ margin: "100px auto", textAlign: "center" }}>
        <Row>
          <Col>
            {/* <img src={error404} alt="error-404" className="errorImage"/> */}
            <Container>
              <h1>500 Error</h1>
              <h2 style={{ margin: "30px auto" }}>
                Oop! Our server is experiencing difficulties. Try again later.
              </h2>
              <Button
                className="download-button"
                variant="danger"
                type="submit"
                style={{ display: "block", margin: "10px auto" }}
              >
                <Link to="/">Visit Voter Guide</Link>
              </Button>
            </Container>
          </Col>
        </Row>
      </Container>
      <Container className="email-bg-container">
        <EmailCollection />
      </Container>
      <Footer />
    </div>
  );
}

export default Error500;
