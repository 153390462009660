import React, { useState, useContext } from "react";
import {
  Form,
  Button,
  Alert,
  Row,
  Col,
  Container,
  Image,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { iconHandRightWhite, iconWarning } from "../../lib/images";
import { LocationSearchInput } from "../index";
import { GlobalContext } from "../../context/GlobalState";
import "./addressCollection.styles.scss";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

function AddressCollection() {
  const { t } = useTranslation();
  let history = useHistory();
  const [address, setAddress] = useState("");
  const [lookupError, setError] = useState("");
  const { addPayload } = useContext(GlobalContext);
  const location = useLocation();

  function handleSubmit(e) {
    e.preventDefault();
    window.session.getAddressLookup(address).then(function (response) {
      if (response && response.lookupError) {
        setError(response.lookupError);
      } else {
        setError("");
        let { cd, sldl, sldu, county, countyDistrict, schoolDistrict, municipality } = response;
        cd = cd && cd.replace(/^0+/, "");
        sldl = sldl && sldl.replace(/^0+/, "");
        sldu = sldu && sldu.replace(/^0+/, "");
        county = county && county.toLowerCase();
        countyDistrict = countyDistrict && countyDistrict.replace(/^0+/, "");
        schoolDistrict = parseInt(schoolDistrict);

        let newUrl =
          (cd ? "ballot/" + cd : "ballot/0") +
          (sldl ? "/" + sldl : "/0") +
          (sldu ? "/" + sldu : "/0") +
          (county ? "/" + county : "/0")+
          (countyDistrict ? "/" + countyDistrict : "/0" )+
          (schoolDistrict ? "/" + schoolDistrict : "/0")+
          (municipality ? "/" + municipality : "/0");
        // let newUrl =
        //   "/ballot/" +
        //   (municipal ? municipal : "0") +
        //   "/" +
        //   (schoolDistrict ? schoolDistrict : "0");

        if (location.pathname !== newUrl) {
          let payload = {
            address: address,
            step: 2,
            isOpen: true,
            query: "" + newUrl,
          };
          addPayload(payload);
          history.push(newUrl);
        }
      }
    });
  }
  return (
    <Form onSubmit={(e) => handleSubmit(e)} id="create_personalized">
      <Container fluid>
        <Row>
          <Col xs={9} md={7} lg={8} xl={5} className="form-col">
            <Form.Group controlId="formAddress">
              <LocationSearchInput updateAddress={(e) => setAddress(e)} />
              {lookupError ? (
                <Alert variant="danger" className="alert-warning">
                  <Image src={iconWarning} className="icon-warning" />
                  {lookupError}
                </Alert>
              ) : null}
            </Form.Group>
          </Col>
          <Col xs={3} md={5} lg={4} xl={4} className="form-col">
            <Button disabled={!address} type="submit" className="button-submit">
              <span className="search-candidate-mobile">
                {t("create_your_guide")}
              </span>
              <Image src={iconHandRightWhite} alt="" />
            </Button>
          </Col>
        </Row>
      </Container>
    </Form>
  );
}

export default AddressCollection;
