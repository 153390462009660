import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Calendar, LineSplit } from "../../components";
import "./RegisterToVote.style.scss";
import { Trans, useTranslation } from "react-i18next";

function RegisterToVote() {
  const { t } = useTranslation();
  return (
    <div className="ctn-register-tovote">
      <div className="title">
        <Trans i18nKey="ih_make_plan_vote">
          <nobr></nobr>
        </Trans>
      </div>
      <div className="leader">{t("how_are_you_going")}</div>
      <div className="cast-vote">
        <Container className="p-0">
          <Row noGutters>
            <Col className="col-12 col-md-4 my-2 px-2 offset-md-2">
              <Link to="/vote-by-mail">
                <button type="button" className="btn-start btn-small btn-plan">
                  {t("by_mail")}
                </button>
              </Link>
            </Col>
            <Col className="col-12 col-md-4 my-2 px-2">
              <Link to="/vote-in-person-early">
                <button type="button" className="btn-start btn-small btn-plan">
                  {t("before_election_day")}
                </button>
              </Link>
            </Col>
          </Row>
          <Row noGutters>
            <Col className="col-12 col-md-4 my-2 px-2 offset-md-4">
              <Link to="/vote-in-person">
                <button type="button" className="btn-start btn-small btn-plan">
                  {t("on_election_day")}
                </button>
              </Link>
            </Col>
            {/* <Col className='col-12 col-md-5 my-2 px-2'>
						<Link to='/learn-more'>
							<button type='button' className='btn-start btn-small btn-plan'>
								Actually, I’m not sure
							</button>
						</Link>
					</Col> */}
          </Row>
        </Container>
      </div>{" "}
      <div className="register-to-vote">
        <div className="description">{t("not_sure")}</div>
        <a href="/#voter-status">
          <button type="button" className="btn-register">
            {t("check_your_registration_status")}
          </button>
        </a>
      </div>
      <LineSplit isGreen="true" />
      <Calendar />
    </div>
  );
}
export default RegisterToVote;
