import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import {
  sponsor1,
  sponsor2,
  sponsor3,
  sponsor4,
  sponsor5,
  sponsor6,
  sponsor7,
  sponsor8,
  sponsor9,
  sponsor10,
  sponsor11,
  sponsor12,
  sponsor13,
  sponsor14,
  sponsor15,
  sponsor16,
} from "../../lib/images";
import "./sponsorBanner.styles.scss";

function SponsorBanner() {
  return (
    <div>
      <div id="about-us">
        <Container className="sponsor-banner">
          <Row noGutters>
            <Col xs="12">
              <Container className="sponsor-container d-none d-lg-block">
                <Row
                  noGutters
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Col xs={6} md={6} lg={3} xl={3}>
                    <a
                      className={sponsor1.link ? "" : "disable-link"}
                      href={sponsor1.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor1.src} alt={sponsor1.altTxt} />
                    </a>
                  </Col>
                  <Col xs={6} md={6} lg={3} xl={3}>
                    <a
                      className={sponsor2.link ? "" : "disable-link"}
                      href={sponsor2.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor2.src} alt={sponsor2.altTxt} />
                    </a>
                  </Col>
                  <Col xs={6} md={6} lg={3} xl={3}>
                    <a
                      className={sponsor3.link ? "" : "disable-link"}
                      href={sponsor3.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor3.src} alt={sponsor3.altTxt} />
                    </a>
                  </Col>
                  <Col xs={6} md={6} lg={3} xl={3}>
                    <a
                      className={sponsor4.link ? "" : "disable-link"}
                      href={sponsor4.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor4.src} alt={sponsor4.altTxt} />
                    </a>
                  </Col>
                </Row>
                <Row
                  noGutters
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Col xs={6} md={6} lg={3} xl={3}>
                    <a
                      className={sponsor5.link ? "" : "disable-link"}
                      href={sponsor5.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor5.src} alt={sponsor5.altTxt} />
                    </a>
                  </Col>
                  <Col xs={6} md={6} lg={3} xl={3}>
                    <a
                      className={sponsor6.link ? "" : "disable-link"}
                      href={sponsor6.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor6.src} alt={sponsor6.altTxt} />
                    </a>
                  </Col>
                  <Col xs={6} md={6} lg={3} xl={3}>
                    <a
                      className={sponsor7.link ? "" : "disable-link"}
                      href={sponsor7.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor7.src} alt={sponsor7.altTxt} />
                    </a>
                  </Col>
                  <Col xs={6} md={6} lg={3} xl={3}>
                    <a
                      className={sponsor8.link ? "" : "disable-link"}
                      href={sponsor8.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor8.src} alt={sponsor8.altTxt} />
                    </a>
                  </Col>
                </Row>
                <Row
                  noGutters
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Col xs={6} md={6} lg={3} xl={3}>
                    <a
                      className={sponsor9.link ? "" : "disable-link"}
                      href={sponsor9.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor9.src} alt={sponsor9.altTxt} />
                    </a>
                  </Col>
                  <Col xs={6} md={6} lg={3} xl={3}>
                    <a
                      className={sponsor10.link ? "" : "disable-link"}
                      href={sponsor10.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor10.src} alt={sponsor10.altTxt} />
                    </a>
                  </Col>
                  <Col xs={6} md={6} lg={3} xl={3}>
                    <a
                      className={sponsor11.link ? "" : "disable-link"}
                      href={sponsor11.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor11.src} alt={sponsor11.altTxt} />
                    </a>
                  </Col>
                  <Col xs={6} md={6} lg={3} xl={3}>
                    <a
                      className={sponsor12.link ? "" : "disable-link"}
                      href={sponsor12.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor12.src} alt={sponsor12.altTxt} />
                    </a>
                  </Col>
                  <Col xs={6} md={6} lg={3} xl={3}>
                    <a
                      className={sponsor13.link ? "" : "disable-link"}
                      href={sponsor13.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor13.src} alt={sponsor13.altTxt} />
                    </a>
                  </Col>
                  <Col xs={6} md={6} lg={3} xl={3}>
                    <a
                      className={sponsor14.link ? "" : "disable-link"}
                      href={sponsor14.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor14.src} alt={sponsor14.altTxt} />
                    </a>
                  </Col>
                  <Col xs={6} md={6} lg={3} xl={3}>
                    <a
                      className={sponsor15.link ? "" : "disable-link"}
                      href={sponsor15.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor15.src} alt={sponsor15.altTxt} />
                    </a>
                  </Col>
                  <Col xs={6} md={6} lg={3} xl={3}>
                    <a
                      className={sponsor16.link ? "" : "disable-link"}
                      href={sponsor16.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor16.src} alt={sponsor16.altTxt} />
                    </a>
                  </Col>
                </Row>
              </Container>
              <Container className="sponsor-container d-lg-none">
                <Row
                  noGutters
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <Col xs={4} md={4} lg={3} xl={3}>
                    <a
                      className={sponsor1.link ? "" : "disable-link"}
                      href={sponsor1.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor1.src} alt={sponsor1.altTxt} />
                    </a>
                  </Col>
                  <Col xs={4} md={4} lg={3} xl={3}>
                    <a
                      className={sponsor2.link ? "" : "disable-link"}
                      href={sponsor2.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor2.src} alt={sponsor2.altTxt} />
                    </a>
                  </Col>
                  <Col xs={4} md={4} lg={3} xl={3}>
                    <a
                      className={sponsor3.link ? "" : "disable-link"}
                      href={sponsor3.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor3.src} alt={sponsor3.altTxt} />
                    </a>
                  </Col>
                  <Col xs={4} md={4} lg={3} xl={3}>
                    <a
                      className={sponsor4.link ? "" : "disable-link"}
                      href={sponsor4.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor4.src} alt={sponsor4.altTxt} />
                    </a>
                  </Col>
                  <Col xs={4} md={4} lg={3} xl={3}>
                    <a
                      className={sponsor5.link ? "" : "disable-link"}
                      href={sponsor5.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor5.src} alt={sponsor5.altTxt} />
                    </a>
                  </Col>
                  <Col xs={4} md={4} lg={3} xl={3}>
                    <a
                      className={sponsor6.link ? "" : "disable-link"}
                      href={sponsor6.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor6.src} alt={sponsor6.altTxt} />
                    </a>
                  </Col>
                  <Col xs={4} md={4} lg={3} xl={3}>
                    <a
                      className={sponsor7.link ? "" : "disable-link"}
                      href={sponsor7.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor7.src} alt={sponsor7.altTxt} />
                    </a>
                  </Col>
                  <Col xs={4} md={4} lg={3} xl={3}>
                    <a
                      className={sponsor8.link ? "" : "disable-link"}
                      href={sponsor8.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor8.src} alt={sponsor8.altTxt} />
                    </a>
                  </Col>
                  <Col xs={4} md={4} lg={3} xl={3}>
                    <a
                      className={sponsor9.link ? "" : "disable-link"}
                      href={sponsor9.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor9.src} alt={sponsor9.altTxt} />
                    </a>
                  </Col>
                  <Col xs={4} md={4} lg={3} xl={3}>
                    <a
                      className={sponsor10.link ? "" : "disable-link"}
                      href={sponsor10.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor10.src} alt={sponsor10.altTxt} />
                    </a>
                  </Col>
                  <Col xs={4} md={4} lg={3} xl={3}>
                    <a
                      className={sponsor11.link ? "" : "disable-link"}
                      href={sponsor11.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor11.src} alt={sponsor11.altTxt} />
                    </a>
                  </Col>
                  <Col xs={4} md={4} lg={3} xl={3}>
                    <a
                      className={sponsor12.link ? "" : "disable-link"}
                      href={sponsor12.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor12.src} alt={sponsor12.altTxt} />
                    </a>
                  </Col>
                  <Col xs={4} md={4} lg={3} xl={3}>
                    <a
                      className={sponsor13.link ? "" : "disable-link"}
                      href={sponsor13.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor13.src} alt={sponsor13.altTxt} />
                    </a>
                  </Col>
                  <Col xs={4} md={4} lg={3} xl={3}>
                    <a
                      className={sponsor14.link ? "" : "disable-link"}
                      href={sponsor14.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor14.src} alt={sponsor14.altTxt} />
                    </a>
                  </Col>
                  <Col xs={4} md={4} lg={3} xl={3}>
                    <a
                      className={sponsor15.link ? "" : "disable-link"}
                      href={sponsor15.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor15.src} alt={sponsor15.altTxt} />
                    </a>
                  </Col>
                  <Col xs={4} md={4} lg={3} xl={3}>
                    <a
                      className={sponsor16.link ? "" : "disable-link"}
                      href={sponsor16.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image src={sponsor16.src} alt={sponsor16.altTxt} />
                    </a>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default SponsorBanner;
