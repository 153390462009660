import React from "react";
import {
  Header,
  Footer,
  EmailSignup,
  LineSplit,
  AboutUs,
  AlertSignup,
} from "../components";
import config from "../lib/config";
import { Helmet } from "react-helmet-async";
import "../components/AboutUs/AboutUs.style.scss";
import { useTranslation } from "react-i18next";
function QuizLanding06() {
  let imageUrl = [
    window.location.protocol,
    "//",
    window.location.host,
    config.seo.default.image,
  ].join("");
  let ogUrl = window.location.href;
  const { t } = useTranslation();
  return (
    <div className="ctn-about-us">
      <Helmet>
        <title>{t("seo_about_title")}</title>
        <meta name="description" content={t("seo_about_page_description")} />
        <meta name="image" content={imageUrl} />
        <meta name="twitter:title" content={t("seo_about_us_tw_title")} />
        <meta
          name="twitter:description"
          content={t("seo_about_us_tw_description")}
        />
        <meta name="twitter:image" content={imageUrl} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta itemProp="name" content={t("seo_about_title")} />
        <meta
          itemProp="description"
          content={t("seo_about_page_description")}
        />
        <meta itemProp="image" content={imageUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={ogUrl} />
        <meta property="og:title" content={t("seo_about_us_page_title")} />
        <meta
          property="og:description"
          content={t("seo_about_page_description")}
        />
        <meta property="og:image" content={imageUrl} />
        {/* <title>{config.seo.aboutUs.title}</title>
          <meta name="description" content={config.seo.aboutUs.page_description}/>
          <meta name="image" content={imageUrl}/>
          <meta name="twitter:title" content={config.seo.aboutUs.tw_title}/>
          <meta name="twitter:description" content={config.seo.aboutUs.tw_description}/>
          <meta name="twitter:image" content={imageUrl}/>
          <meta name="twitter:card" content="summary_large_image"/>
          <meta itemProp="name" content={config.seo.aboutUs.title}/>
          <meta itemProp="description" content={config.seo.aboutUs.page_description}/>
          <meta itemProp="image" content={imageUrl}/>
          <meta property="og:type" content="website"/>
          <meta property="og:url" content={ogUrl}/>
          <meta property="og:title" content={config.seo.aboutUs.page_title}/>
          <meta property="og:description" content={config.seo.aboutUs.page_description}/>
          <meta property="og:image" content={imageUrl}/> */}
      </Helmet>
      <div className="home-top-email">
        <AlertSignup />
      </div>
      <Header />
      <div id="page-wrap">
        <LineSplit />
        <div className="ctn-about-us-header">
          <div className="ctn-about-us-title">{t("we_re_making_voting")}</div>
        </div>
        <LineSplit />
        <AboutUs />
        <LineSplit />
        <EmailSignup isMidPage="true" />
        <LineSplit />
        <Footer flat={true} />
      </div>
    </div>
  );
}

export default QuizLanding06;
