import React from "react";
import {
  Header,
  Footer,
  EmailSignup,
  LineSplit,
  BallotCuring,
  AlertSignup,
} from "../components";

import "../components/QuizLandingPage/QuizLandingPage.style.scss";
import config from "../lib/config";
import { Helmet } from "react-helmet-async";
/* Ballot Curing */
import { Trans, useTranslation } from "react-i18next";

function BallotCuringPage() {
  let imageUrl = [
    window.location.protocol,
    "//",
    window.location.host,
    config.seo.default.image,
  ].join("");
  let ogUrl = window.location.href;
  const { t } = useTranslation();
  return (
    <div className="ctn-quiz-landing04">
      <Helmet>
        <title>{t("seo_ballotCuring_title")}</title>
        <meta
          name="description"
          content={t("seo_ballotCuring_page_description")}
        />
        <meta name="image" content={imageUrl} />
        <meta name="twitter:title" content={t("seo_ballotCuring_tw_title")} />
        <meta
          name="twitter:description"
          content={t("seo_ballotCuring_tw_description")}
        />
        <meta name="twitter:image" content={imageUrl} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta itemProp="name" content={t("seo_ballotCuring_title")} />
        <meta
          itemProp="description"
          content={t("seo_ballotCuring_page_description")}
        />
        <meta itemProp="image" content={imageUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={ogUrl} />
        <meta property="og:title" content={t("seo_ballotCuring_page_title")} />
        <meta
          property="og:description"
          content={t("seo_ballotCuring_page_description")}
        />
        <meta property="og:image" content={imageUrl} />
      </Helmet>
      {/* <div className="home-top-email">
        <AlertSignup />
      </div> */}
      <Header />
      <div id="page-wrap">
        <LineSplit isGreen="true" />
        <div className="quiz-landing-header">
          <div className="quiz-landing-title quiz-landing-title-width-90 quiz-landing-title-mobile-full">
            <Trans i18nKey="bc_if_ballot_not_accepted_header">
              <span className="bg-emphasis-red"></span>
            </Trans>
          </div>
        </div>
        <LineSplit isGreen="true" />
        <BallotCuring />
        <LineSplit isGreen="true" />
        <EmailSignup isMidPage="true" />
        <LineSplit isGreen="true" />
        <Footer flat={true} />
      </div>
    </div>
  );
}

export default BallotCuringPage;
