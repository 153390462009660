import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { EmailForm } from "../index.js";
import { FacebookShareButton } from "react-share";
import config from "../../lib/config";

import "./EmailCollection.styles.scss";

function EmailCollection({ showLinks, showEmail, link, runoff }) {
  let shareUrl = window.location.origin;
  let shareDescription = config.seo.default.share_description;
  return (
    <div
      id={showLinks ? "external-links" : ""}
      className={
        showLinks ? "collection-background show-links" : "collection-background"
      }
    >
      <Container>
        <Row>
          <Col xs="12" md={{ span: 10, offset: 1 }}>
            {showEmail ? (
              <EmailForm
                buttonText={"Email me my guide >"}
                link={link}
                showPrivacy={true}
                toDownload={true}
              />
            ) : null}
            {showLinks ? (
              <div>
                <p>
                  <a
                    href="https://www.acluga.org/en/how-track-your-absentee-ballot-application-and-absentee-ballot"
                    target="__blank"
                    rel="noopener noreferrer"
                  >
                    Track your ballot
                  </a>{" "}
                  or{" "}
                  <a
                    href="https://www.mvp.sos.ga.gov/MVP/mvp.do"
                    target="__blank"
                    rel="noopener noreferrer"
                  >
                    request an absentee ballot
                  </a>
                </p>
                <p>
                  For additional information and questions, call the Election
                  Protection hotline at 1-866-OUR VOTE or visit{" "}
                  <a
                    href="https://866ourvote.org/state-information/georgia/"
                    target="__blank"
                    rel="noopener noreferrer"
                  >
                    866 Our Vote
                  </a>
                  .
                </p>
              </div>
            ) : null}
            {runoff ? (
              <div>
                <h2>Georgia voters have the power. </h2>
                <p>
                  On January 5th, we will decide two critical U.S. Senate races.
                  We showed up in record numbers to make history in November,
                  and now we have a chance to do it again. Elections in Georgia
                  can be decided by just a few hundred votes — your vote has the
                  power to elect leaders who prioritize justice and fairness in
                  our communities. Together, our votes are even more powerful
                  and can make the difference.
                </p>
                <div style={{ textAlign: "center" }}>
                  <FacebookShareButton url={shareUrl} quote={shareDescription}>
                    <Button
                      className="download-button"
                      variant="danger"
                      type="submit"
                      style={{ display: "block", margin: "5px auto" }}
                    >
                      {"Share now >"}
                    </Button>
                  </FacebookShareButton>
                </div>
              </div>
            ) : null}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default EmailCollection;
