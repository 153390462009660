import React, { useState, useEffect } from "react";
import {
  CandidatePanel,
  Header,
  LineSplit,
  Footer,
  EmailSignup,
  AddressCollection,
  AlertSignup,
} from "../components";
import { useLocation, Redirect } from "react-router-dom";
import config from "../lib/config";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import "./racePage.styles.scss";

function RacePage(props) {
  const location = useLocation();
  const [raceData = { notLoaded: true }, setRace] = useState({
    notLoaded: true,
  });
  const [pageId] = useState(0);
  const { t, i18n } = useTranslation();
  let raceName;
  let lang = i18n.language;
  raceName = props.targetedQuery;
  const fetchData = async () => {
    let payload = raceName;
    window.session.getRaceData(payload, lang).then(function (response) {
      if (response && response.lookupError) {
        setRace(null);
      } else {
        let raceData = response;
        setRace(raceData);
      }
    });
  };
  let sldl = props.race && props.race.replace("hd", "");

  let seoHD = config.seo.soloStateHouse[sldl];
  let image = seoHD && config.seo.default.image;
  let imageUrl = [
    window.location.protocol,
    "//",
    window.location.host,
    image,
  ].join("");
  let titleShare = config.seo.soloTemplate.share_title.replace("{sldl}", sldl);
  let titleSEO = config.seo.soloTemplate.title.replace("{sldl}", sldl);
  useEffect(() => {
    //
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId, lang]);
  const race =
    (location.state && location.state.race) || (raceData && raceData[0]);

  if (raceData && raceData.notLoaded) {
    return <div />;
  }

  if (!raceData || !race) {
    return <Redirect to="404" />;
  }
  return (
    <div>
      {seoHD ? (
        <Helmet>
          <title>{titleSEO}</title>
          <meta
            name="description"
            content={config.seo.soloTemplate.description}
          />
          <meta name="image" content={imageUrl} />
          <meta name="twitter:title" content={titleShare} />
          <meta
            name="twitter:description"
            content={config.seo.soloStateHouse[sldl].description}
          />
          <meta name="twitter:image" content={imageUrl} />
          <meta itemProp="name" content={config.seo.default.title} />
          <meta
            itemProp="description"
            content={config.seo.soloTemplate.description}
          />
          <meta itemProp="image" content={imageUrl} />
          <meta property="og:title" content={titleShare} />
          <meta
            property="og:description"
            content={config.seo.soloStateHouse[sldl].description}
          />
          <meta property="og:image" content={imageUrl} />
        </Helmet>
      ) : null}
      <div>
        <div className="home-top-email">
          <AlertSignup />
        </div>
        <Header />
        <div id="page-wrap">
          {/* {raceName ?
                        <button className="back-button" type="button" onClick={() => history.push('/')}>{"< MAIN VOTER GUIDE"}</button>
                        : <button className="back-button" type="button" onClick={() => history.goBack()}>{"< RETURN TO THE VOTER GUIDE"}</button>
                    } */}
          {lang === "en" ? (
            ""
          ) : (
            <div className="language-disclaimer" noGutters>
              {t("translation_disclaimer")}
            </div>
          )}
          <div className="race-page-address">
            <LineSplit />
            <h3>{t("enter_your_address")}</h3>
            <AddressCollection />
            <LineSplit />
            <CandidatePanel details={race} isRacePage={true} />
            <LineSplit />
          </div>
          <EmailSignup isMidPage="true" />
          <LineSplit />
          <Footer flat={true} />
        </div>
      </div>
    </div>
  );
}

export default RacePage;
