import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import config from "../../lib/config";
import Candidate from "./Candidate.component.jsx";
import Dropdown from "react-dropdown";
import { Tab, Container, Row, Col, Nav, Image } from "react-bootstrap";
import "./candidatePanel.styles.scss";
import { iconDropdown } from "../../lib/images";

const getInternationalizedContent = (field, language) => {
  // Map 'kr' to 'ko' for fetching from Sanity
  let mappedLanguage = language === 'kr' ? 'ko' : language;
  if (Array.isArray(field)) {
    const langContent = field.find((item) => item._key === mappedLanguage);
    return langContent ? langContent.value : null;
  }
  return null;
};

/**
 * @typedef {object} SlugObject
 * @property {string} current Slug
 * @property {string} _type Internal type of slug
 */

/**
 * @typedef {object} CandidateObject
 * @property {BioObject} bio Biography info
 * @property {EndorsementsObject} endorsements Endorsement  info
 * @property {string} _id Internal - ID
 * @property {string} linkedin_url LinkedIn URL
 * @property {slug} slug Slug
 * @property {string} name Full name
 * @property {string} job_title Job title
 * @property {string} personalEducation Personal education
 * @property {string} experience Experience
 * @property {string} _type Internal - type of candidate
 * @property {string} _key Internal - candidate key
 * @property {Array.<IssuesObject>} issues Stance of issues
 * @property {Array.<VotingHistoryObject>} votingHistory Voting record on bills
 * @property {string} tiktok_url TikTok URL
 * @property {boolean} incumbent Is an incumbent?
 * @property {string} instagram_url Instagram URL
 * @property {string} website Website
 * @property {race} race Race the candidate is in
 * @property {string} _rev Internal - revision
 * @property {string} youtube_url Youtube URL
 * @property {string} _updatedAt Internal - latest updated timestamp
 * @property {string} party Party affiliation
 * @property {string} facebook_url Facebook URL
 * @property {string} _createdAt Internal - creation date
 * @property {string} twitter_url Twitter URL
 */

/**
 * @typedef {object} BioObject
 * @property {[]} markDefs markDefs
 * @property {Array.<SanityChildrenObject>} children List of children
 * @property {string} _type Internal - type
 * @property {string} _key Internal - key
 */

/**
 * @typedef {object} EndorsementsObject
 * @property {[]} markDefs markDefs
 * @property {Array.<SanityChildrenObject>} children List of children
 * @property {string} _type Internal - type
 * @property {string} _key Internal - key
 */

/**
 * @typedef {object} SanityChildrenObject
 * @property {string} _key Internal - key
 * @property {string} _type Internal - type
 * @property {string} text Text
 */

/**
 * @typedef {object} RaceObject
 * @property {string} _ref Internal - ref
 * @property {string} _type Internal - type
 * @property {string} _key Internal - key
 */

/**
 * @typedef {object} IssuesObject
 * @property {string} _key Internal - key
 * @property {string} issueName Name
 * @property {string} _type Internal - type
 * @property {DescriptionObject} description Description
 */

/**
 * @typedef {object} DescriptionObject
 * @property {string} _type Internal - type
 * @property {string} _key Internal - key
 * @property {[]} markDefs markDefs
 * @property {Array.<SanityChildrenObject>} children List of children
 */

/**
 * @typedef {object} VotingHistoryObject
 * @property {string} billName Name of the bill
 * @property {string} billCategory Category of the bill
 * @property {string} description Description of the bill
 * @property {string} votingRecord How the candidate voted
 * @property {string} _key Internal - key
 * @property {string} billNumber Official bill number
 */

/**
 * A tabbed candidates' info panel for a specific race,
 * including their Biography, Experience and Endorsements.
 * Optionally, their stance on Issues and their Voting History on bills.
 *
 * @component
 * @param {CandidatePanelPropsObject} props - The component props.
 * @returns {React.ReactElement} A tabbed candidates info panel for a specific race
 */
function CandidatePanel(props) {
  let isRecommendation = config.featureRecommendation;
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [key, setKey] = useState("bio");
  const { t, i18n } = useTranslation();
  const language = i18n.language || "en";
  let details = props.details;
  let candidates = (details && details.candidates) || [];
  let recommendedCandidate,
    enableVP,
    disableBio,
    disableIssues,
    disableEndorsements,
    disableHistory,
    isUncontested,
    recommendedText;
  recommendedCandidate = candidates.find((e) => e.recommended);
  recommendedText =
    (recommendedCandidate &&
      recommendedCandidate.name &&
      "VOTE " + recommendedCandidate.name.toUpperCase()) ||
    "NONE";
  let candidateTab = recommendedCandidate || candidates[0];

  const issuesTabsMap = config.issuesTabs; // Allowed Issues categories
  let availableIssuesTabs = [];
  let issuesTabsDisplay = [];
  let issuesTabsLinkDisplay = [];

  const votingHistoryTabsMap = config.votingHistoryTabs; // Allowed Voting History categories
  let votingHistoryTabsDisplay = [];
  let votingHistoryTabsLinkDisplay = [];
  let availableVotingHistoryTabs = [];

  /**
   * START if candidates
   */
  if (candidates && candidates.length > 0) {
    /**
     * START Issues
     */
    // Get unique issues across all candidates
    availableIssuesTabs = [
      ...new Set(
        candidates
          .map((candidate) =>
            candidate.issues?.map((issueItem) => issueItem?.issueName?.trim())
          )
          .flat()
          .filter(
            (issueCategory) =>
              typeof issueCategory ===
                "string" /* filter out categories using config */ &&
              Object.values(issuesTabsMap).find(
                (categoryAllowed) => categoryAllowed === issueCategory
              )
          )
      ),
    ];

    // For each Issues category
    availableIssuesTabs.map((issuesTab) => {
      // Get key from map
      const issuesTabKey = Object.keys(issuesTabsMap).find(
        (key) => issuesTabsMap[key] === issuesTab
      );
      issuesTabsDisplay.push({ value: issuesTabKey, label: t(issuesTab) });
      issuesTabsLinkDisplay.push(
        <Nav.Item
          className={
            key === issuesTabKey ? "issues-tab-active issues-tab" : "issues-tab"
          }
          key={issuesTabKey}
        >
          <Nav.Link eventKey={issuesTabKey} title={issuesTab.toUpperCase()}>
            {issuesTabsMap[issuesTab]}
          </Nav.Link>
        </Nav.Item>
      );
      availableIssuesTabs.push({ value: issuesTabKey, label: t(issuesTab) });
    });
    /**
     * END Issues
     **/

    /**
     * START Voting History
     **/
    // Get unique bill categories across all candidates
    availableVotingHistoryTabs = [
      ...new Set(
        candidates
          .map((candidate) =>
            candidate.votingHistory?.map((vhItem) =>
              vhItem?.billCategory?.trim()
            )
          )
          .flat()
          .filter(
            (billCategory) =>
              typeof billCategory ===
                "string" /* filter out categories using config */ &&
              Object.values(votingHistoryTabsMap).find(
                (categoryAllowed) => categoryAllowed === billCategory
              )
          )
      ),
    ];

    // For each Voting History category
    availableVotingHistoryTabs.map((votingHistoryTab) => {
      // Get key from map
      const votingHistoryTabKey = Object.keys(votingHistoryTabsMap).find(
        (key) => votingHistoryTabsMap[key] === votingHistoryTab
      );
      votingHistoryTabsDisplay.push({
        value: votingHistoryTabKey,
        label: t(votingHistoryTab),
      });
      votingHistoryTabsLinkDisplay.push(
        <Nav.Item
          className={
            key === votingHistoryTabKey
              ? "issues-tab-active issues-tab"
              : "issues-tab"
          }
          key={votingHistoryTabKey}
        >
          <Nav.Link
            eventKey={votingHistoryTabKey}
            title={votingHistoryTab.toUpperCase()}
          >
            {issuesTabsMap[votingHistoryTab]}
          </Nav.Link>
        </Nav.Item>
      );
      availableVotingHistoryTabs.push({
        value: votingHistoryTabKey,
        label: t(votingHistoryTab),
      });
    });
    /**
     * END Voting History
     **/
  }
  /**
   * END if candidates
   **/

  // let sortedList = candidates && candidates.sort((x, y) => { return (x.recommended ? -1 : 1) });
  // let candidatesDisplay = props.isRacePage ? sortedList : (sortedList && sortedList.slice(0, 3));
  const partyOrder = [
    'Democrat',
    'Republican',
    'Libertarian',
    'Green Party',
    'Independent',
    'Non-Partisan'
  ];
  
  candidates.sort((a, b) => {
    return partyOrder.indexOf(a.party) - partyOrder.indexOf(b.party);
  });
  let candidatesDisplay = candidates;
  if (details && details.race_name === "President") {
    enableVP = true;
  }

  // Update disableBio and disableEndorsements checks
  // const bioContent = getInternationalizedContent(candidateTab.bio, language);
  // if (!bioContent || bioContent.length === 0) {
  //   disableBio = true;
  // }
  if (
    !(
      candidateTab.endorsements?.length &&
      candidateTab.endorsements[0].children.length &&
      candidateTab.endorsements[0].children[0].text
    )
  ) {
    // disableEndorsements = true;
  }
  if (issuesTabsDisplay.length < 1) {
    disableIssues = true;
  }
  if (votingHistoryTabsDisplay?.length < 1) {
    disableHistory = true;
  }
  let isMinimal =
    Array.isArray(candidatesDisplay) && candidatesDisplay.every((candidate) => {
      return ( !candidate["bio"]?.length > 0 )
    });
  if (candidates && candidates.length === 1) isUncontested = true;
  useEffect(() => {
    setKey("bio");
  }, [language]);
  let raceTitle =
    details && (details.race_name_localized || details.race_name_display);
  return (
    <div>
      <Container className="ballot-container" fluid>
        <Container>
          <Row className="title-background">
            <Container
              className={
                !isMinimal ? "issue-nav-container" : "issue-nav-container-min"
              }
            >
              <div className="title">{raceTitle}</div>
              {isRecommendation ? (
                isUncontested ? (
                  <h4 className="recommendation-text">UNCONTESTED RACE</h4>
                ) : (
                  <h4 className="recommendation-text">
                    OUR RECOMMENDATION: <span>{recommendedText}</span>
                  </h4>
                )
              ) : null}
              {!isMinimal ? (
                <Tab.Container
                  id="controlled-tab"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                  style={{ display: "inline-flex" }}
                  transition="fade"
                >
                  <Row className="tab-manager">
                    <Nav.Item
                      className="main-tab"
                      style={{ display: disableBio ? "none" : "block" }}
                    >
                      <Nav.Link
                        eventKey="bio"
                        title="Biography"
                        className={key === "bio" ? "active-tab" : ""}
                      >
                        {t("biography")}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item
                      className="main-tab"
                      style={{ display: enableVP ? "block" : "none" }}
                    >
                      <Nav.Link
                        eventKey="vp"
                        title="Vice President"
                        className={key === "vp" ? "active-tab" : ""}
                      >
                        {t("vice_president")}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item
                      className="main-tab"
                      style={{ display: disableBio ? "none" : "block" }}
                    >
                      <Nav.Link
                        eventKey="experience"
                        title="Experience"
                        className={key === "experience" ? "active-tab" : ""}
                      >
                        {t("experience")}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item
                      className="main-tab"
                      style={{ display: disableIssues ? "none" : "block" }}
                    >
                      <Nav.Link
                        eventKey={
                          issuesTabsDisplay.length > 0
                            ? issuesTabsDisplay[0].value
                            : "bio"
                        }
                        title="On The Issues"
                        className={
                          issuesTabsDisplay.find(
                            (element) => element.value === key
                          )
                            ? "active-tab"
                            : ""
                        }
                        disabled={issuesTabsDisplay.length <= 0}
                      >
                        {t("issues")}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item
                      className="main-tab"
                      style={{
                        display: disableEndorsements ? "none" : "block",
                      }}
                    >
                      <Nav.Link
                        eventKey="endorsements"
                        title="Endorsements"
                        className={key === "endorsements" ? "active-tab" : ""}
                      >
                        {t("endorsements")}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item
                      className="main-tab"
                      style={{ display: disableHistory ? "none" : "block" }}
                    >
                      <Nav.Link
                        eventKey={
                          votingHistoryTabsDisplay.length > 0
                            ? votingHistoryTabsDisplay[0].value
                            : "bio"
                        }
                        title="Voting History"
                        className={
                          votingHistoryTabsDisplay.find(
                            (element) => element.value === key
                          )
                            ? "active-tab"
                            : ""
                        }
                        disabled={votingHistoryTabsDisplay.length <= 0}
                      >
                        {t("voting_history")}
                      </Nav.Link>
                    </Nav.Item>

                    {key !== "bio" &&
                    key !== "vp" &&
                    key !== "endorsements" &&
                    key !== "experience" ? (
                      <Row className="tab-manager-dropdown">
                        <span className="issue-tab-text">
                          {issuesTabsLinkDisplay.length > 0 &&
                          availableIssuesTabs.find(
                            (tabKey) => tabKey.value === key
                          )
                            ? t("on_the_issues") + " :"
                            : null}
                          {votingHistoryTabsLinkDisplay.length > 0 &&
                          availableVotingHistoryTabs.find(
                            (tabKey) => tabKey.value === key
                          )
                            ? t("on_the_issues") + " :"
                            : null}
                        </span>

                        {issuesTabsDisplay.length > 0 &&
                        availableIssuesTabs.find(
                          (tabKey) => tabKey.value === key
                        ) ? (
                          <Dropdown
                            options={issuesTabsDisplay}
                            value={key}
                            onChange={(e) => setKey(e.value)}
                            className="issues-dropdown clickable"
                            arrowOpen={
                              <Image
                                src={iconDropdown}
                                alt=""
                                className="icon-drop-down open"
                              />
                            }
                            arrowClosed={
                              <Image
                                src={iconDropdown}
                                alt=""
                                className="icon-drop-down"
                              />
                            }
                          />
                        ) : null}

                        {votingHistoryTabsDisplay.length > 0 &&
                        availableVotingHistoryTabs.find(
                          (tabKey) => tabKey.value === key
                        ) ? (
                          <Dropdown
                            options={votingHistoryTabsDisplay}
                            value={key}
                            onChange={(e) => setKey(e.value)}
                            className="issues-dropdown clickable"
                            arrowOpen={
                              <Image
                                src={iconDropdown}
                                alt=""
                                className="icon-drop-down open"
                              />
                            }
                            arrowClosed={
                              <Image
                                src={iconDropdown}
                                alt=""
                                className="icon-drop-down"
                              />
                            }
                          />
                        ) : null}
                      </Row>
                    ) : null}
                  </Row>
                </Tab.Container>
              ) : (
                ""
              )}
            </Container>
          </Row>
        </Container>
        {candidatesDisplay.map(function (candidate, i) {
          return (
            <Row key={i} className="zero-margin">
              <Container className="candidate">
                <Candidate
                  details={candidate}
                  racename={details.race_name}
                  isRacePage={props.isRacePage}
                  tab={key}
                  isMinimal={false}
                />
              </Container>
            </Row>
          );
        })}
        {availableVotingHistoryTabs.find((tabKey) => tabKey.value === key) && (
          <>
            {isMobile ? (
              <Row className="zero-margin">
                <Col className="voting-history-source voting-history-source-mobile">
                  <p style={{flexWrap: "wrap", justifyContent: "center"}}>
                    <span>
                      <img
                        src="/images/plural-policy.png"
                        alt="Plural Policy"
                      />
                    </span>
                    <span style={{ textAlign: "left", width: "min-content", minWidth: "170px"}}>
                      {t("plural_policy")}
                    </span>
                  </p>
                </Col>
              </Row>
            ) : (
              <Row className="zero-margin">
                <Col className="voting-history-source voting-history-source-desktop">
                  <p>
                    {t("plural_policy")}
                    <img src="/images/plural-policy.png" alt="Plural Policy" />
                  </p>
                </Col>
              </Row>
            )}
          </>
        )}
      </Container>
    </div>
  );
}

export default CandidatePanel;
