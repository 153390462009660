import React, { useState, useContext } from "react";
import { Image, Row, Col, Container } from "react-bootstrap";
import "./header.styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import MediaQuery from "react-responsive";
import HeaderNavBar from "./HeaderNavBar.component";
import { logoHeader } from "../../lib/images";
import { Link } from "react-router-dom";
import { LanguageSwitcher } from "../index";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalState";

function Header(props) {
  const { t, i18n } = useTranslation();
  let lang = i18n.language;
  const [showMenu, setShowMenu] = useState(false);
  const onClickToggleMenu = () => {
    setShowMenu(!showMenu);
  };
  const toggleMenu = () => {
    setShowMenu(false);
  };
  const { resetGlobalState } = useContext(GlobalContext);

  let history = useHistory();
  const resetBallot = () => {
    resetGlobalState();
    history.push("/");
  };
  return (
    <>
      <div className="header-container">
        <Row className="header-content" noGutters>
          <Col xs="1">
            <LanguageSwitcher />
          </Col>
          <Col
            xs={{ size: 10, offset: 0 }}
            sm={{ size: 8, offset: 1 }}
            className="header-title"
          >
            <Link to="/" onClick={() => resetBallot()}>
              <Image
                src={logoHeader}
                className="logo-header"
                alt="the-georgia-voter-guide"
              />
            </Link>
          </Col>
          <Col xs="1" sm="2" className="header-learn-more clickable">
            <MediaQuery maxWidth={991}>
              <FontAwesomeIcon
                icon={faBars}
                size="lg"
                color="#193946"
                onClick={onClickToggleMenu}
              />
            </MediaQuery>
            <h4 className="clickable" onClick={onClickToggleMenu}>{t("menu")}</h4>
          </Col>
          {showMenu ? <HeaderNavBar toggleMenu={toggleMenu} /> : ""}
        </Row>
      </div>
      <Container fluid className="language-disclaimer-container">
        {lang === "en" ? (
          ""
        ) : (
          <div className="language-disclaimer" noGutters>
            {t("translation_disclaimer")}
          </div>
        )}
      </Container>
    </>
  );
}

export default Header;
