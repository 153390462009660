import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import * as qs from "qs";
import { useLocation } from "react-router-dom";
import config from "../../lib/config";
import "./SignMeUp.style.scss";
import { Trans, useTranslation } from "react-i18next";

function SignMeUp() {
  const isMobile = useMediaQuery({ query: "(max-width: 998px)" });
  const [status, setStatus] = useState("INITIAL");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [zip, setZip] = useState("");
  const [sourceParam, setSourceParam] = useState(
    sessionStorage.getItem(config.akSourceName),
  );
  const location = useLocation();
  const { t } = useTranslation();
  useEffect(() => {
    if (!sourceParam)
      setSourceParam(
        qs.parse(location.search, { ignoreQueryPrefix: true })[
          config.akSourceName
        ],
      ); // If no stored source exists, check QS. Use if found.
  }, [location, sourceParam]);

  function handleChangeZip(e) {
    if (
      e.target.value.match("(^[0-9]+$|^$|^s$)") != null &&
      !(e.target.value.length > 5)
    ) {
      setZip(e.target.value);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    var formData = new URLSearchParams();
    formData.append("page", "gavghomepagefooter");
    formData.append("email", email);
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("zip", zip);
    if (sourceParam) formData.append("source", sourceParam);

    window.session.postSubscribeEmail(formData).then(
      function (res) {
        setStatus("SUCCESS");
      },
      function (e) {
        setStatus("FAILED");
      },
    );
  }
  const renderForm = (
    <div>
      <div className="title">
        <Trans i18nKey="get_all_info_you_need">
          <span className={`${isMobile ? "underline-emphasis" : ""}`}></span>
        </Trans>
      </div>
      <div className="cast-vote">
        <Form id="content_signup" onSubmit={(e) => handleSubmit(e)}>
          <div className="row">
            <div className="col-12 col-md-9">
              <Row>
                <Col xs="6">
                  <Form.Control
                    type="text"
                    placeholder={t("first_name") + "*"}
                    onChange={(e) => setFirstName(e.target.value)}
                    className={`cast-vote-item ${
                      isMobile ? "responsive-input" : ""
                    }`}
                    required
                  />
                </Col>
                <Col xs="6">
                  <Form.Control
                    type="text"
                    placeholder={t("last_name") + "*"}
                    onChange={(e) => setLastName(e.target.value)}
                    className={`cast-vote-item ${
                      isMobile ? "responsive-input" : ""
                    }`}
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <Form.Control
                    type="email"
                    placeholder={t("email") + "*"}
                    onChange={(e) => setEmail(e.target.value)}
                    className={`cast-vote-item ${
                      isMobile ? "responsive-input" : ""
                    }`}
                    required
                  />
                </Col>
                <Col xs="6">
                  <Form.Control
                    type="text"
                    placeholder={t("zip") + "*"}
                    value={zip}
                    onChange={(e) => handleChangeZip(e)}
                    className={`cast-vote-item ${
                      isMobile ? "responsive-input" : ""
                    }`}
                    required
                  />
                </Col>
              </Row>
            </div>
            <div className="sign-me-up col-12 col-md-2 col-md-offset-1">
              <button type="submit" className="btn-register">
                <span>{t("sign_up")}</span>
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
  const renderStatus = (status) => {
    switch (status) {
      case "INITIAL":
        return renderForm;
      case "SUCCESS":
        return <div className="title">{t("thank_you_election_updates")}</div>;
      case "FAILED":
        return (
          <div className="title">
            There has been an issue, please try again.
          </div>
        );
      default:
        return renderForm;
    }
  };
  return <div className="ctn-sign-me-up">{renderStatus(status)}</div>;
}
export default SignMeUp;
