import React from "react";
import "./LineSplit.style.scss";
function LineSplit({ isGreen, isShort }) {
  return (
    <div
      className={
        "ctn-line-split " +
        (isGreen ? "bg-green " : "") +
        (isShort ? "short-line-split " : "")
      }
    ></div>
  );
}
export default LineSplit;
